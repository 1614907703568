import { React, useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';


// material
import {
    Card,
    IconButton,
    Stack,
    Button,
    TextField,
    Divider,
    FormControl,
    MenuItem,
    InputLabel, Select,
    Autocomplete,
    Tooltip,
    Modal,
    Box,
    Typography
} from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { startOfMonth, endOfMonth, format } from 'date-fns';
import { useSnackbar } from 'notistack';
import Moment from 'moment';
import { Icon } from '@iconify/react';
import Iconify from '../../Iconify';
import URLS from '../../../services/urls.service';
import LoadingProgressBar from '../../../theme/overrides/LoadingProgressBar';
import Page from '../../Page';
import { handleError } from '../../Common/Utils';
import AppCache from '../../../services/AppCache';

// ----------------------------------------------------------------------


// ----------------------------------------------------------------------
const style = {
    position: 'absolute',
    top: '50%',
    justifyContent: 'center',
    left: '50%',
    transform: 'translate(-50%, -50%)',

    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 10,
    p: 4,
};


export default function ServiceRequestBDTrackerListingPage({ trackerMasterId, trackerName, allowAdd, allowEdit, allowDelete  }) {
    console.log("trackerMasterId>>>>>>>>>>>>>>>>", trackerMasterId)
    const { enqueueSnackbar } = useSnackbar();
    const TABLE_HEAD = [
       
        { field: 'IssueLoggedBy', headerName: 'Issue Logged By', width: 150 },
        { field: 'Issue', headerName: 'Issue', width: 100 },
        { field: 'IssueDescription', headerName: 'Issue Description', width: 150 },
        { field: 'IssueCategory', headerName: 'Issue Category', width: 100 },
        { field: 'DealerCode', headerName: 'Dealer Code', width: 100 },
        { field: 'DateReported', headerName: 'Date Reported', width: 100, renderCell: (params) => params.value === null ? '' : Moment(params.value).format('DD-MMM-YYYY') },
        { field: 'Priority', headerName: 'Priority', width: 100 },
        { field: 'AssignedTo', headerName: 'Assigned To', width: 130 },
        { field: 'SAPLComments', headerName: 'SAPL Comments', width: 200 },
        { field: 'Status', headerName: 'Status', width: 100 },
        { field: 'TargetedFixDate', headerName: 'Targeted Fix Date', width: 80, renderCell: (params) => params.value === null ? '' : Moment(params.value).format('DD-MMM-YYYY') },
        { field: 'RefrenceDocument', headerName: 'Reference Document', width: 120 },
        { field: 'ServiceRequestId', headerName: 'Service Request ID', width: 80 },
        { field: 'InsertedAt', headerName: 'Inserted At', width: 80, renderCell: (params) => params.value === null ? '' : Moment(params.value).format('DD-MMM-YYYY') },
        { field: 'InsertedBy', headerName: 'Inserted By', width: 80 },
        {
            field: 'actions',
            headerName: '',
            type: 'actions',
            width: 20,
            renderCell: (cellValues) => (
                <div>
                    <IconButton
                        onClick={(event) => {
                            handleClick(event, cellValues);
                        }}
                    >
                        <Iconify icon="ic:outline-edit" width="20px" height="20px" color="primary.main" />
                    </IconButton>
                </div>
            ),
        },
        {
            field: 'delete',
            headerName: '',
            type: 'delete',
            width: 20,
            renderCell: (cellValues) => (
                <IconButton onClick={() => handleDelete(cellValues)}>
                    <Iconify icon="ic:baseline-delete" width="20px" height="20px" color="primary.main" />
                </IconButton>
            ),
        },
       
    ];

   
    const [effectiveFromValue, setEffectiveFromValue] = useState(startOfMonth(new Date()));
    const [effectiveToValue, setEffectiveToValue] = useState(endOfMonth(new Date()));
    const [reports, setReports] = useState([]);
    const [columns, setColumns] = useState([])

    const [isLoading, setIsLoading] = useState();
    const [issueId, setIssueId] = useState('');
    const [editData, setEditData] = useState([])
    const [open, setOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [deleteRowId, setDeleteRowId] = useState('');
    console.log("reports", reports)
    // executive ID
    const CurrentLoggedInUser = AppCache.GetCurrentLoggedInUser();
    const [userId, setUserId] = useState([]);

    const [loadTrackerData, setLoadTrackerData] = useState([])

    useEffect(() => {
        setUserId(CurrentLoggedInUser.ExecutiveId);
    }, []);

    const location = useLocation();
    const navigate = useNavigate();

    const handleEffFrom = (newValue) => {
        setEffectiveFromValue(newValue)
    };
    const handleEffTo = (newValue) => {
        setEffectiveToValue(newValue)
    };
    useEffect(() => {
        if (trackerMasterId) {
            setIsLoading(true);
            URLS.loadIssueTrackerData({ Id: trackerMasterId }) // Passing the trackerMasterId to the API
                .then((response) => {

                    setLoadTrackerData(response.data);
                    setIsLoading(false);
                })
                .catch((error) => {
                    setIsLoading(false);

                    console.error("Error loading tracker data:");
                });
        } else {
            console.error("trackerMasterId is not set");
        }
    }, [trackerMasterId]);

    const generateColumns = (data) => {
        if (!data || data.length === 0) return [];

        const firstRow = data[0]; // Assuming the first row of data contains the field names

        // Generate dynamic columns based on the response data
        const columns = Object.keys(firstRow).map((key) => ({
            field: key,
            headerName: key.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase()), // Converts camelCase to readable name
            width: 120,
        }));

        // Add the Actions column at the end
        const actionColumn = {
            field: 'actions',
            headerName: 'Actions',
            type: 'actions',
            width: 150,
            renderCell: (cellValues) => (
                <div>
                    {allowEdit === true && (
                        <IconButton
                            onClick={(event) => handleClick(event, cellValues)}
                            color="primary"
                        >
                            <Iconify icon="ic:outline-edit" width="20px" height="20px" />
                        </IconButton>
                    )}
                    {allowDelete === true && (
                        <IconButton
                            onClick={() => handleDelete(cellValues)}
                            color="secondary"
                        >
                            <Iconify icon="ic:baseline-delete" width="20px" height="20px" color="primary.main" />
                        </IconButton>
                    )}
                </div>
            ),
        };

        // Append the action column to the dynamic columns
        return [...columns, actionColumn];
    };

    const onFilterClick = () => {
        setIsLoading(true);
        const formDate = format(effectiveFromValue, 'dd-MMM-yyyy');
        const toDate = format(effectiveToValue, 'dd-MMM-yyyy');

        const payload = { FromDate: formDate, ToDate: toDate, IssueId: issueId, TrackerMasterId: trackerMasterId, Id: userId }

        URLS.filterIssueTrackerData(payload)
            .then((response) => {
                const data = response.data?.DataTable || []; 
                setReports(data);
                setColumns(generateColumns(data)); // Pass the data to generate columns
                setIsLoading(false);
                enqueueSnackbar("Records Fetched successfully", { variant: 'success' });
            })
            .catch(error => {
                // On error
                setReports([]);
                enqueueSnackbar("Record not found", { variant: 'success' });
                setIsLoading(false);
            });
    };



    const handleClick = (event, cellValues) => {
        console.log(cellValues.row.Id, "cellValues", cellValues)
        setIsLoading(true);
        URLS.editLoadData({ Id: cellValues.row.ID, TrackerMasterId: trackerMasterId })
            .then((response) => {
                const jsonDataString = JSON.stringify(response.data);

                const modifiedData = JSON.parse(jsonDataString);
                console.log("modifiedData", modifiedData)
                setEditData(modifiedData);
                navigate('/dashboard/servicerequesttrackerdetailspage', { state: { tracker: modifiedData, trackerMasterId, loadTrackerData, trackerName } })

            })
            .catch(error => {
                // On error
                enqueueSnackbar("Record not found", { variant: 'success' });
                setIsLoading(false);

            });
    }

    const handleDelete = (cellValues) => {
        console.log('Delete clicked', cellValues.row);
        setSelectedRow(cellValues.row); // Set the selected row data
        setDeleteRowId(cellValues.row.ID)
        setOpen(true); // Open the modal
    };
    const handleClose = () => {
        setOpen(false);
        setSelectedRow(null);
    };

    const handleConfirmDelete = (confirmed) => {
        console.log("confirmed", confirmed)
        if (confirmed !== null) {
            const payload = { ServiceId: selectedRow.ServiceRequestId, LogginUserId: userId, TrackerMasterId: trackerMasterId, Id: deleteRowId };
            const formDate = format(effectiveFromValue, 'dd-MMM-yyyy');
            const toDate = format(effectiveToValue, 'dd-MMM-yyyy');
            const filterPayload = { FromDate: formDate, ToDate: toDate, IssueId: issueId, TrackerMasterId: trackerMasterId, Id: userId }

            URLS.deleteIssueTrackerData(payload)
                .then((response) => {
                    console.log("response", response)
                    if (response.status === 200) {
                        enqueueSnackbar("Deleted data successfully", { variant: 'success' });
                        setOpen(false);
                        

                        URLS.filterIssueTrackerData(filterPayload)
                            .then((response) => {
                                setReports(response.data);
                                setIsLoading(false);
                                enqueueSnackbar("Records Fetched successfully", { variant: 'success' });
                            })
                            .catch(error => {
                                // On error
                                setReports([])
                                enqueueSnackbar("Record not found", { variant: 'success' });
                                setIsLoading(false);

                            });

                        // Filter out the deleted row from the reports
                       
                    }
                })
                .catch((error) => {
                    enqueueSnackbar("Failed to delete record", { variant: 'error' });
                    console.error(error);
                });
        }
    };

  
    const handleReset = () => {
        setReports([]);
        setEffectiveFromValue(startOfMonth(new Date()));
        setEffectiveToValue(endOfMonth(new Date()));
       
    }

    useEffect(() => {
        setReports([]);
        setEffectiveFromValue(startOfMonth(new Date()));
        setEffectiveToValue(endOfMonth(new Date()));
        setIssueId('')

    }, [location.key])


    return (
        <Page title="JJCore Incident Tracker  Listing">
            <Card>
                <Stack direction="column" m={1} spacing={2}>
                   

                    <Stack direction="row" spacing={2}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                                label="From Date"
                                inputFormat="dd/MMM/yyyy"
                                value={Moment(effectiveFromValue).format('DD-MMM-yyyy')}
                                onChange={handleEffFrom}
                                renderInput={(params) => <TextField fullWidth size="small" {...params} sx={{ flexGrow: 1 }} />}
                            />
                        </LocalizationProvider>

                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                                label="To Date"
                                inputFormat="dd/MMM/yyyy"
                                value={Moment(effectiveToValue).format('DD-MMM-yyyy')}
                                onChange={handleEffTo}
                                renderInput={(params) => <TextField fullWidth size="small" {...params} sx={{ flexGrow: 1 }} />}
                            />
                        </LocalizationProvider>

                        <Stack direction="row" spacing={1} sx={{ flexGrow: 1 }}>
                            <Tooltip title="Filter">
                                <Button variant="contained" size="small" onClick={onFilterClick}>
                                    <Icon icon="ic:outline-filter-alt" width="25px" height="25px" />
                                </Button>
                            </Tooltip>

                            {allowAdd === true && (
                                <Tooltip title="Add New">
                                    <Button
                                        component={RouterLink}
                                        to="/dashboard/servicerequesttrackerdetailspage"
                                        variant="contained"
                                        size="small"
                                        state={{
                                            tracker: {
                                                Id: '',
                                                ControlType: ''
                                            },
                                            trackerMasterId,
                                            loadTrackerData,
                                            trackerName
                                        }}
                                    >
                                        <Icon icon="ic:baseline-plus" width="25px" height="25px" />
                                    </Button>
                                </Tooltip>
                            )}

                            <Tooltip title="Reset">
                                <Button variant="contained" size="small" onClick={handleReset}>
                                    <Icon icon="ic:twotone-restart-alt" width="25px" height="25px" />
                                </Button>
                            </Tooltip>
                        </Stack>
                    </Stack>
                </Stack>

                <Divider />
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2" textAlign="center">
                            Are you sure you want to delete this record?
                        </Typography>
                        <Stack justifyContent="center" spacing={2} mt={2} direction="row">
                            <Button onClick={handleConfirmDelete} variant="contained">
                                Yes
                            </Button>
                            <Button onClick={handleClose} variant="contained" >
                                No
                            </Button>
                        </Stack>
                    </Box>
                </Modal>

                <div style={{ height: '73vh', width: '100%' }}>
                    <DataGrid
                        rows={reports}
                        columns={columns}
                        density={'compact'}
                        getRowId={(row) => reports.indexOf(row)}
                        components={{
                            Toolbar: GridToolbar,
                        }}
                        sx={{
                            '& .MuiDataGrid-columnHeaderTitle': {
                                whiteSpace: 'normal',
                                overflow: 'visible',
                                lineHeight: '1.2',
                                wordWrap: 'break-word',
                            },
                        }}
                        pageSize={25} 
                    />
                </div>

            </Card>

            {isLoading && (<LoadingProgressBar sx={{ py: 6 }} />)}
        </Page>
    );


}