import { React, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useLocation, Navigate, useNavigate } from "react-router-dom";
import { useSnackbar } from 'notistack';

// material
import { styled } from '@mui/material/styles';
import { Stack, Container, Card, Typography, Divider, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// components
import { useFormik, Form, FormikProvider, isNaN } from 'formik';
import axios from 'axios';
import { startOfMonth, endOfMonth } from 'date-fns';
import Moment from 'moment';
import XLSX from 'sheetjs-style';
import * as FileSaver from 'file-saver';
import { handleError } from '../../../components/Common/Utils';
import AppCache from '../../../services/AppCache';
import URLS from '../../../services/urls.service';

import ModalPopUp from '../../../components/Common/ModalPopUp';
import Page from '../../../components/Page';
import IssueCloserDashboard from '../../../components/SidebarMenuComponents/ApprovalComponent/IssueCloserDashboard';
import LoadingProgressBar from '../../../theme/overrides/LoadingProgressBar';


// ----------------------------------------------------------------------------------------------
const ContentStyle = styled('div')(({ theme }) => ({

    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1, 1)
}));
export default function IssueCloser() {
   
    const CurrentLoggedInUser = AppCache.GetCurrentLoggedInUser();
    const [userId, setUserId] = useState(CurrentLoggedInUser.ExecutiveId || '');

    const { enqueueSnackbar } = useSnackbar();

    const [openModalForCancel, setOpenModalCancel] = useState(false);
    const [value, setValue] = useState(0);
    const [hide, setHide] = useState(false);

    const [effectiveFromValue, setEffectiveFromValue] = useState(startOfMonth(new Date()));
    const [effectiveToValue, setEffectiveToValue] = useState(endOfMonth(new Date()));

    const [historyFromValue, setHistoryFromValue] = useState(startOfMonth(new Date()));
    const [historyToValue, setHistoryToValue] = useState(endOfMonth(new Date()));

    const [isLoading, setIsLoading] = useState();

    const [historyData, setHistoryData] = useState([]);
    const [summaryData, setSummaryData] = useState([]);
    const [pendingListData, setPendingListData] = useState([]);
    const [ticketData, setTicketData] = useState([]);

    const [ticketActionDropdown, setTicketActionDropdown] = useState({});
    const [historyActionDropdown, setHistoryActionDropdown] = useState({});

    const [reOpenCheckbox, setReOpenCheckbox] = useState({});
    const [remarkText, setRemarkText] = useState({});

    const [ticketServiceDatavisible, setTicketServiceDatavisible] = useState(false);
    const [ticketServiceData, setTicketServiceData] = useState([]);

    const [historyServiceDatavisible, setHistoryServiceDatavisible] = useState(false);
    const [historyServiceData, setHistoryServiceData] = useState([]);

    const [userName, setUserName] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const navigate = useNavigate();

    const [openModal, setOpenModal] = useState(false);
    const [selectedIssueId, setSelectedIssueId] = useState(null);

    // Function to handle opening the modal
    const handleOpenModal = (issueId) => {
        setSelectedIssueId(issueId);

        URLS.loadServices({ IssueId: issueId })
            .then((response) => {
                setIsLoading(false);
                setTicketServiceData(response.data);
            })
            .catch(error => {
                handleError(error);
            });
        setOpenModal(true);
    };

    // Function to handle closing the modal
    const handleCloseModal = () => {
        setOpenModal(false);
        setSelectedIssueId(null);
    };
    // message
    const message = {
        forCancel: 'Do you want to cancel ? ',
    };

    // cancle confirm
    const forCancelConfirm = (value) => {
        if (value) {
            navigate('/dashboard/oa/oamaster', { replace: true }); 
        }
        else {
            setOpenModalCancel(false);
        }
    };

    // Tabs
    const handleChangeValue = (event, newValue) => {
        setValue(newValue);
    };

    // Hide
    const handleHide = () => {
        setHide(true)
    };

  
    // cancel
    const handelCancelClick = () => {
        setOpenModalCancel(true);
    };
    const handleIssueIdClick = (IssueIds) => {
        setTicketServiceDatavisible(true) // Toggle view on click

    };
    const handleHistoryIssueIdClick = (IssueIds) => {
        setHistoryServiceDatavisible(true) // Toggle view on click

        URLS.loadServices({ IssueId: IssueIds })
            .then((response) => {
                setIsLoading(false);
                setHistoryServiceData(response.data);
            })
            .catch(error => {
                handleError(error);
            });
    };
    const exportToExcel = () => {
        const fileExtension = '.xlsx';
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

        // Check if reports are available
        if (!pendingListData || pendingListData.length === 0) {
            console.log("No data to export.");
            return;
        }

        // Prepare headers based on response keys
        const headers = Object.keys(pendingListData[0]);

        // Prepare the row data based on reports data
        const dataToExport = pendingListData.map((row) => 
             headers.map(header => row[header]) // Map each header to the corresponding row data
        );

        const dataWithHeaders = [headers, ...dataToExport]; // Combine headers with data rows
        const ws = XLSX.utils.aoa_to_sheet(dataWithHeaders); // Convert to AOA format

        const wb = { Sheets: { data: ws }, SheetNames: ['data'] }; // Create workbook

        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' }); // Write workbook to buffer
        const data = new Blob([excelBuffer], { type: fileType }); // Create a blob from the buffer

        const fileName = `PendingIssues${fileExtension}`; // Define file name

        FileSaver.saveAs(data, fileName); // Save the file
    };
    const handleExport = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const handleEffFrom = (newValue) => setEffectiveFromValue(newValue);
    const handleEffTo = (newValue) => setEffectiveToValue(newValue);

    const handleHistoryFrom = (newValue) => setHistoryFromValue(newValue);
    const handleHistoryTo = (newValue) => setHistoryToValue(newValue);

    const [modifiedRows, setModifiedRows] = useState({});
    const [ticketModifiedRows, setTicketModifiedRows] = useState({});

  
    useEffect(() => {
        setUserId(CurrentLoggedInUser.ExecutiveId);
        setUserName(CurrentLoggedInUser.ExecutiveName);
    }, [])

    const handleActionChange = (event, issueId) => {
        const {value} = event.target;
        setTicketActionDropdown(prevState => ({
            ...prevState,
            [issueId]: value // Assigns 1 for Reopened and 0 for Closed
        }));
    };
    const handleTicketRemarkChange = (event, issueId) => {
        const { value } = event.target;
        // Update the remark for the specific issueId
        setTicketModifiedRows((prev) => ({
            ...prev,
            [issueId]: {
                ...prev[issueId],
                IssuesReopenRemark: value,
            },
        }));
    };

    const handleReOpenChange = (event, issueId) => {
        const isReopen = event.target.checked;
        setModifiedRows((prev) => ({
            ...prev,
            [issueId]: {
                ...(prev[issueId] || {}),
                IsReopen: isReopen,
            },
        }));
    };
    const handleRemarkChange = (event, issueId) => {
        const remark = event.target.value;
        setModifiedRows((prev) => ({
            ...prev,
            [issueId]: {
                ...(prev[issueId] || {}),
                IssuesReopenRemark: remark,
            },
        }));
    };


    console.log("userId", userId, CurrentLoggedInUser.ExecutiveId)
    
    const onGenerate = () => {
        setIsLoading(true);

        const formattedFromDate = Moment(effectiveFromValue).format('DD-MMM-yyyy');
        const formattedToDate = Moment(effectiveToValue).format('DD-MMM-yyyy');
        const payload = {
            Id: userId,
            FromDate: formattedFromDate,
            ToDate: formattedToDate,

        };
        URLS.loadDashboardIssueCloserPendingList(payload)
                .then((response) => {
                    setIsLoading(false);
                    setSummaryData(response.data.HistoryIssues || []);
                    setPendingListData(response.data.PendingIssues || []);
                })
                .catch(error => {
                    handleError(error);
                });
       
    }

    const onGenerateHistory = () => {
        setIsLoading(true);

        const formattedFromDate = Moment(historyFromValue).format('DD-MMM-yyyy');
        const formattedToDate = Moment(historyToValue).format('DD-MMM-yyyy');
        const payload = {
            Id: userId,
            FromDate: formattedFromDate,
            ToDate: formattedToDate,

        };
        URLS.loadTicketIssueCloserPendingList(payload)
            .then((response) => {
                setIsLoading(false);
                setHistoryData(response.data.HistoryIssues || []);

            })
            .catch(error => {
                handleError(error);
            });

    }
    useEffect(() => {
        const formattedFromDate = Moment(effectiveFromValue).format('DD-MMM-yyyy');
        const formattedToDate = Moment(effectiveToValue).format('DD-MMM-yyyy');
        const payload = {
            Id: userId,
            FromDate: formattedFromDate,
            ToDate: formattedToDate,
        };

        if (value === 1) { // Check if the "Ticket" tab is selected
            setIsLoading(true); // Start loading when a tab is selected

            URLS.loadTicketIssueCloserPendingList(payload)
                .then((response) => {
                    setTicketData(response.data.PendingIssues || []);
                    setIsLoading(false); // Stop loading
                })
                .catch(error => {
                    setIsLoading(false); // Stop loading on error
                    handleError(error);
                });
        } else if (value === 2) { // Check if the "History" tab is selected
            setIsLoading(true); // Start loading when a tab is selected

            URLS.loadTicketIssueCloserPendingList(payload) // Replace with your actual API call for history
                .then((response) => {
                    setHistoryData(response.data.HistoryIssues || []);
                    setIsLoading(false); // Stop loading
                })
                .catch(error => {
                    setIsLoading(false); // Stop loading on error
                    handleError(error);
                });
        }
    }, [value, effectiveFromValue, effectiveToValue]);

   

    const saveTicket = () => {
        const payload = Object.keys(ticketActionDropdown).map(issueId => {
            const rowData = ticketData.find(row => row.IssueId === parseInt(issueId, 10)) || {};
            const actionValue = ticketActionDropdown[issueId];

            const payloadItem = {
                Id: userId,
                IssueId: parseInt(issueId, 10),
                ClientId: rowData.ClientId || 0,
                IssuesReopenRemark: ticketModifiedRows[issueId]?.IssuesReopenRemark || ""
            };

            if (actionValue === 1) {
                payloadItem.IsReopen = true;
            } else if (actionValue === 0) {
                payloadItem.IsReopen = false;
            }

            return payloadItem;
        });

        const formattedFromDate = Moment(effectiveFromValue).format('DD-MMM-yyyy');
        const formattedToDate = Moment(effectiveToValue).format('DD-MMM-yyyy');

        const loadPayload = {
            Id: userId,
            FromDate: formattedFromDate,
            ToDate: formattedToDate,
        };

        URLS.saveTicketIssueCloserPendingList(payload)
            .then(response => {
                enqueueSnackbar("Records saved successfully", { variant: 'success' });
                setTicketActionDropdown({});
                setTicketModifiedRows({})

                return URLS.loadTicketIssueCloserPendingList(loadPayload);
            })
            .then(loadResponse => {
                setTicketData(loadResponse.data.PendingIssues || []);
                setIsLoading(false);
            })
            .catch(error => {
                console.error("Error:", error);
                setIsLoading(false);
                enqueueSnackbar("Records not saved", { variant: 'error' });
            });

    };


    const saveHistory = () => {
        const payload = Object.keys(modifiedRows).map(issueId => ({
                Id: userId,
                IssueId: parseInt(issueId, 10),
                IsCheck: modifiedRows[issueId].IsReopen || false,
                IssuesReopenRemark: modifiedRows[issueId].IssuesReopenRemark || ""
        }));
        const formattedFromDate = Moment(effectiveFromValue).format('DD-MMM-yyyy');
        const formattedToDate = Moment(effectiveToValue).format('DD-MMM-yyyy');

        const loadPayload = {
            Id: userId,
            FromDate: formattedFromDate,
            ToDate: formattedToDate,
        };

        URLS.saveIssueCloserPendingList(payload)
            .then(response => {
                enqueueSnackbar("Records saved successfully", { variant: 'success' });
                setModifiedRows({})

                return URLS.loadTicketIssueCloserPendingList(loadPayload);
            })
            .then(loadResponse => {
               setHistoryData(loadResponse.data.PendingIssues || []);
                setIsLoading(false);
            })
            .catch(error => {
                console.error("Error:", error);
                setIsLoading(false);
                enqueueSnackbar("Records not saved", { variant: 'error' });
            });
        
        console.log("history>payload", payload)
    }
   
    console.log("summaryData", summaryData, pendingListData, ticketData)
    
   

    return (
        <Page title="  Issue Closer | tiketic" >
                    <Typography variant="h5" color="#b71c1c" marginLeft={3} marginTop={1} >
                        Issue Closer Pending List
                    </Typography>

                    <ContentStyle>
                        
                <IssueCloserDashboard value={value} handleChangeValue={handleChangeValue} handelCancelClick={handelCancelClick}
                    hide={hide} handleHide={handleHide}
                    handleEffFrom={handleEffFrom} handleEffTo={handleEffTo} effectiveFromValue={effectiveFromValue} effectiveToValue={effectiveToValue}
                    onGenerate={onGenerate} handleHistoryFrom={handleHistoryFrom} handleHistoryTo={handleHistoryTo} historyFromValue={historyFromValue} historyToValue={historyToValue}
                    summaryData={summaryData} pendingListData={pendingListData} historyData={historyData} ticketData={ticketData} handleActionChange={handleActionChange}
                    ticketActionDropdown={ticketActionDropdown} historyActionDropdown={historyActionDropdown}
                    handleReOpenChange={handleReOpenChange} reOpenCheckbox={reOpenCheckbox} handleRemarkChange={handleRemarkChange} remarkText={remarkText} onGenerateHistory={onGenerateHistory}
                    saveTicket={saveTicket} saveHistory={saveHistory} modifiedRows={modifiedRows} handleTicketRemarkChange={handleTicketRemarkChange} ticketModifiedRows={ticketModifiedRows}
                    exportToExcel={exportToExcel} handleExport={handleExport} handleClose={handleClose} open={open} anchorEl={anchorEl} handleIssueIdClick={handleIssueIdClick} ticketServiceDatavisible={ticketServiceDatavisible}
                    ticketServiceData={ticketServiceData} handleHistoryIssueIdClick={handleHistoryIssueIdClick} historyServiceData={historyServiceData} historyServiceDatavisible={historyServiceDatavisible}
                    openModal={openModal} handleOpenModal={handleOpenModal} handleCloseModal={handleCloseModal}
                            />
                    </ContentStyle>

                   
                {openModalForCancel && <ModalPopUp openCloseValue={setOpenModalCancel} msg={message.forCancel} confirm={forCancelConfirm} />}

            {isLoading && (<LoadingProgressBar sx={{ py: 6 }} />)}

        </Page>
    );
}

