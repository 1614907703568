import { useRef, useState, useEffect } from 'react';
import styled from "styled-components";
import { Link, NavLink as RouterLink, matchPath, useLocation, useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';
import {
    Stack,
    IconButton,
    Typography,
    List,
    ListItemText,
} from '@mui/material';
import Iconify from './Iconify';
import MenuPopover from './MenuPopover';
import Scrollbar from './Scrollbar';

const NavLabel = styled.span`
    font - size: 10rem;
   text-align: center;
`;

const SidebarNavLink = styled(Link)`
    padding: 0.8rem 1.25rem;
    text - decoration: none;
    display: flex;
    align-items: center;
     justifyContent:space-between;
      &.active {
    background-color:#ADD8E6;
  }
`;

//   const Icon = styled(MaterialIcon)`
//   color: #d1d5db;
//    width: 1.5rem;
//    height: 1.5rem;
//    margin - right: 1rem;
//    `;

//  const Button = styled.button`
//  padding: 0.8rem 1.25rem;
//  text-decoration: none;
//  display: flex;
//  align-items: center;
//  width: 100%;
//  outline: none;
//  border: none;
//  background: transparent;
//  cursor: pointer;
//  text-align: center;
//  justifyContent:space-between;
//   `;

const Block = styled.div`
     padding-left: 30px;
`;

const resolveLinkPath = (childTo, parentTo) => `${parentTo}/${childTo}`;

const NavItemHeader = props => {
    const { item, onOpenSidebar, menuUrl, handleChildItemClick } = props;
    const { MenuName, path, Children, MenuIcon, Level } = item;
    const anchorRef = useRef(null);
    //  console.log("headerToPath ", path);
    const location = useLocation();
    const navigate = useNavigate();

    const [open, setOpen] = useState(null);

    const handleOpen = (event) => {
        setOpen(event.currentTarget);
    };

    const handleClose = () => {
        setOpen(false);
        setIsBlack('false');
        setBold('normal');
        setHighlightColor('none');
        setborder('none');
        setShadow('none');

        setRotateChevron('90deg');
        setColourIndex(selectedColourIndex - 1)

        setExpand(expanded => !expanded);

    };
    const [rotateChevron, setRotateChevron] = useState('90deg');

    const [expanded, setExpand] = useState(
        location.pathname.includes(path)
    );

    const subMenuscolors = ['#fbe9e7', '#fbe9e7', '#ffccbc'];
    //  const subMenuscolors = ['#4B371C', '#9A7B4F', '#795C34'];
    const [selectedColourIndex, setColourIndex] = useState(0);
    const [textColor, setTextColor] = useState('black');  // textcolor
    const [isBlack, setIsBlack] = useState(true);
    const [isBold, setBold] = useState('normal');
    const [isHighlightColor, setHighlightColor] = useState('none');
    const [isborder, setborder] = useState('none');
    const [isShadow, setShadow] = useState('none');
    const [lastClickedMenuItem, setLastClickedMenuItem] = useState(null);
    const previousPathRef = useRef(null);

    useEffect(() => {
        previousPathRef.current = location.pathname;
    }, [location]);

    const handleMenuItemClick = (menuName) => {
        // Perform any additional actions before reloading the page if needed
        if (lastClickedMenuItem === menuName) {
            window.location.reload(); // Reloads the current page if clicking the same menu item
        } else {
            // Handle navigation or other actions for a new menu item click
            setLastClickedMenuItem(menuName);
        }
    };
    const onExpandChange = e => {
        e.preventDefault();
        setExpand(expanded => !expanded);
        // setIsBlack(!isBlack);
        setIsBlack(true);
        // setTextColor(isBlack ? 'white' : 'grey');
        setBold('bold');
        setRotateChevron(isBlack ? '0deg' : '90deg');
        setOpen(e.currentTarget);
        setHighlightColor('#ffccbc');
        setborder('1px solid #ffccbc');
        setShadow('2px 2px 2px grey');
        const newColourIndex = Level;
        if (subMenuscolors[newColourIndex]) {
            setColourIndex(newColourIndex);
        }
        else {
            setColourIndex(0);
        }
        if (Children.length === 0) {
            handleClose();
            setOpen(false);
            setIsBlack('false');
            setBold('normal');
            setHighlightColor('none');
            setborder('none');
            setShadow('none');
            setExpand(expanded => !expanded);
            setRotateChevron('90deg');
            // setColourIndex(0);
            navigate(path, { relpace: true });
        }
        /* handleMenuItemClick() */
    };

    return (
        <>
            {onOpenSidebar === 'false' &&
                <List open={Boolean(open)}
                    onClick={onExpandChange}
                    value={isBlack}

                    sx={{
                        color: 'white',
                        borderRadius: "10px",
                        backgroundColor: isHighlightColor,
                        boxShadow: isShadow,
                        textAlign: 'center',

                        border: isborder,
                        userSelect: 'none',
                        "&.Mui-focusVisible": {
                            backgroundColor: "#fbe9e7"
                        },
                        ":hover": {
                            cursor: "pointer",
                            color: "#fbe9e7",

                        },
                    }}>
                    <Stack alignItems="center" direction="column" mb={1} >
                        {/* <Icon icon={MenuIcon} sx={{ padding: '50px' }} width="20" height="20" /> */}
                        <Iconify icon={MenuIcon} width="25px" height="25px" />
                        <Typography sx={{ color: textColor, fontSize: '12px' }}>
                            <ListItemText disableTypography primary={MenuName} onClick={() => handleChildItemClick(item.MenuURL)} />
                        </Typography>
                    </Stack>
                </List>
            }

            {onOpenSidebar === 'true' &&
                <List open={Boolean(open)}
                    onClick={onExpandChange}
                    value={isBlack}
                    PaperProps={{
                        onMouseEnter: handleOpen,
                        onMouseLeave: handleClose,
                    }}
                    sx={{
                        color: 'white',
                        backgroundColor: isHighlightColor,
                        border: isborder,
                        borderRadius: "5px 5px 5x 5px",
                        borderBottomWidth: 3,
                        borderLeftWidth: 2,
                        borderRightWidth: 2,
                        boxShadow: isShadow,
                        userSelect: 'none',
                        "&.Mui-focusVisible": {
                            backgroundColor: "#fbe9e7"
                        },
                        ":hover": {
                            // backgroundColor: "#e3f2fd",
                            // backgroundColor: "lightgrey",
                            backgroundColor: "#ffccbc",
                            borderRight: "4px solid #bf360c",
                            cursor: "pointer",
                            borderRadius: "5px",
                            boxShadow: " 2px 2px 2px grey",
                            transform: "scale3d(1.01, 1.01, 1)",
                            color: "white",
                        },
                    }}>

                    <Stack
                        direction="row"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"  >
                        <Stack ml={2} alignItems="left" direction="row" justifyContent="space-between">
                            {/* <Icon icon={MenuIcon} sx={{ padding: '50px' }} width="20" height="20" /> */}

                            {MenuIcon.length > 0 && MenuIcon !== "" && MenuIcon !== "null" &&
                                <IconButton sx={{ color: textColor }} width="20" height="20">
                                    <Iconify icon={MenuIcon} />
                                </IconButton>
                            }
                            <Typography sx={{ color: textColor, mt: 0.5, fontSize: '14px', paddingLeft: '6px', marginTop: '8px', wordBreak: 'break-all' }}>
                                <ListItemText disableTypography primary={MenuName} onClick={() => handleChildItemClick(item.MenuURL)} />
                            </Typography>
                        </Stack>
                        <Stack alignItems="right" sx={{ paddingRight: '10px' }}>
                            {Children.length ? <Icon icon="ic:baseline-keyboard-arrow-right" width="15" height="15" rotate={rotateChevron} /> : ""}
                        </Stack>
                    </Stack>
                </List>
            }


            <MenuPopover
                open={Boolean(open)}
                anchorEl={open}
                onClick={handleClose}
                sx={{
                    backgroundColor: subMenuscolors[selectedColourIndex],
                    // backgroundColor: '#3B1F22',
                    '& .MuiMenuItem-root': {
                        typography: 'body2',
                        borderRadius: 0.75,
                    },
                }}

            >
               
                <Scrollbar
                    sx={{
                        height: 1,
                        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' }
                    }}
                >
                    {Children.map((item, index) => {
                        const key = `${item.MenuName}-${index}`;
                        const { MenuName, Children } = item;
                        return (
                            <div key={key}  >
                                <NavItemHeader item={{
                                    ...item,
                                    path: resolveLinkPath(item.MenuAlias, props.item.path),
                                    // path: resolveLinkPath(item.path, props.item.path),
                                }} onOpenSidebar='true' handleChildItemClick={handleChildItemClick} />
                            </div>
                        );
                    })}
                </Scrollbar>
            </MenuPopover>

        </>
    );
};
export default NavItemHeader;
