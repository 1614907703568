import { useLocation, Navigate, useParams } from "react-router-dom";
// material
import { styled } from '@mui/material/styles';
import { Stack, Container, Card, Typography } from '@mui/material';
import { React, useEffect, useState } from 'react';
// components

import URLS from '../../../services/urls.service';
import Page from '../../../components/Page';
import AppCache from '../../../services/AppCache';

import ServiceRequestBDTrackerListingPage from "../../../components/SidebarMenuComponents/ServiceRequestComponent/ServiceRequestBDTrackerListingPage";
// ----------------------------------------------------------------------------------------------
const ContentStyle = styled('div')(({ theme }) => ({

    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1, 1)
}));
export default function ServiceRequestBDTrackerListing({ menuUrl }) {

    const location = useLocation();
    const trackerData = location.state;
    const CurrentLoggedInUser = AppCache.GetCurrentLoggedInUser();
    const [trackerName, setTrackerName] = useState("");
    const [trackerId, setTrackerId] = useState("");
    const [allowAdd, setAllowAdd] = useState("");
    const [allowEdit, setAllowEdit] = useState("");
    const [allowDelete, setAllowDelete] = useState("");

    const [trackerMasterId, setTrackerMasterId] = useState("");
    const [userId, setUserId] = useState([]);

    useEffect(() => {
        setUserId(CurrentLoggedInUser.ExecutiveId);
    }, []);

    useEffect(() => {
        if (menuUrl) {
            const [, id] = menuUrl.split('|'); // Extract trackerId from menuUrl (path|id)
            setTrackerId(id); // Set trackerId state from extracted ID
        }
    }, [menuUrl]);

    useEffect(() => {
        if (trackerId) {
            console.log("Calling API with trackerId:", trackerId);
            URLS.getTrackerName({ Id: trackerId })
                .then((response) => {
                    setTrackerName(response.data[0].TrackerName);
                    setTrackerMasterId(response.data[0].Id);
                    setAllowAdd(response.data[0].AllowAdd);
                    setAllowEdit(response.data[0].AllowEdit);
                    setAllowDelete(response.data[0].AllowDelete);
                })
                .catch(error => {
                    console.log("Error fetching tracker name:", error);
                });
        }
    }, [trackerId]); 

    console.log("trackerMasterId>>>>>>", menuUrl, trackerId)
    return (
        <Page title={trackerName || "tiketic"} >
            <Typography variant="h5" color="#b71c1c" marginLeft={3} marginTop={1} >
                {trackerName || "Service Request Incident Tracker  "}
              
            </Typography>

            <ContentStyle>
                <ServiceRequestBDTrackerListingPage trackerData={trackerData} trackerName={trackerName} trackerMasterId={trackerMasterId} allowAdd={allowAdd} allowEdit={allowEdit} allowDelete={allowDelete} />
            </ContentStyle>
        </Page>
    );
}

