import { useLocation, Navigate } from "react-router-dom";
import { React, useEffect, useState } from 'react';

// material
import { styled } from '@mui/material/styles';
import { Stack, Container, Divider, Typography } from '@mui/material';
import URLS from '../../../services/urls.service';
// components
import Page from '../../../components/Page';
import ServiceRequestBDTrackerDetails from "../../../components/SidebarMenuComponents/ServiceRequestComponent/ServiceRequestBDTrackerDeatils";
// ----------------------------------------------------------------------------------------------
const ContentStyle = styled('div')(({ theme }) => ({

    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1, 1)
}));
export default function ServiceRequestBDTracker() {
  

    const location = useLocation();
    const { tracker, loadTrackerData, trackerMasterId, trackerName } = location.state;
    
    console.log("details", loadTrackerData, trackerMasterId, )
    return (
       
        <Page title={trackerName || "tiketic"} >
            <Typography variant="h5" color="#b71c1c" marginLeft={3} marginTop={1} >
                {trackerName || "Service Request Incident Tracker  Listing"}
            </Typography>
            <Divider/>
            <ContentStyle>
                <ServiceRequestBDTrackerDetails loadTrackerData={loadTrackerData} tracker={tracker} trackerMasterId={trackerMasterId }  />
            </ContentStyle>
        </Page>
    );
}

