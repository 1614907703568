import * as Yup from 'yup';
import * as React from 'react';
// material
import { Stack, Button, Typography, Divider, Tooltip, Select, MenuItem, FormControlLabel, TextField, Checkbox, TextareaAutosize, Modal, Box, IconButton } from '@mui/material';

import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { styled } from '@mui/material/styles';
import Moment from 'moment';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import { Icon } from '@iconify/react';

const accordionStyle = {
    backgroundColor: '#fbe9e7',
    minHeight: '10px',
    height: '30px',
    marginTop: '10px',
    marginBottom: '10px'
    // Add other CSS properties as needed
};

const remarkTextFieldStyle = {
    width: '48.5%'
}
const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    maxHeight: '80vh',
    overflow: 'auto',
    borderRadius: '8px',
};
export default function IssueCloserHistory({ columns, row, fieldSets, handelCancelClick,
    handleHistoryFrom, handleHistoryTo, historyFromValue, historyToValue, historyData, handleRemarkChange, remarkText,
    reOpenCheckbox, handleReOpenChange, onGenerateHistory, saveHistory, modifiedRows, handleHistoryIssueIdClick, historyServiceData, historyServiceDatavisible,
    openModal, handleOpenModal, handleCloseModal
}) {

    const history = [
        {
            field: 'IssueId',
            headerName: 'Id',
            width: 80,
            renderCell: (params) => {
                const issueId = params.row.IssueId;

                return (
                    <div
                        role="button" // Adds accessibility role
                        tabIndex={0} // Makes the div focusable
                        style={{
                            cursor: 'pointer',
                            color: 'blue',
                        }}
                        onMouseEnter={(e) => {
                            e.currentTarget.style.textDecoration = 'underline';
                        }}
                        onMouseLeave={(e) => {
                            e.currentTarget.style.textDecoration = 'none';
                        }}
                        onClick={() => handleOpenModal(issueId)}
                        onKeyDown={(e) => {
                            // Adds keyboard interactivity
                            if (e.key === 'Enter' || e.key === ' ') {
                                handleOpenModal(issueId);
                            }
                        }}
                    >
                        {params.value}
                    </div>
                );
            },
        }, 
        {
            field: 'Issuedate',
            headerName: 'Date',
            width: 100,
            renderCell: (params) => Moment(params.value).format('DD-MMM-YYYY')
        },
        {
            field: 'Remark',
            headerName: 'Remarks',
            width: 300,
        
        },

        {
            field: 'ActionRemark',
            headerName: 'Action ',
            flex: 1,
            renderCell: (params) => {
                const issueId = params.row.IssueId;
                return (
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', alignItems: 'flex-end', position: 'relative' }}>
                        <div style={{ flex: 0.3, display: 'flex', flexDirection: 'column', gap: '4px' }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={modifiedRows[issueId]?.IsReopen || false}
                                        onChange={(event) => handleReOpenChange(event, issueId)}
                                        color="primary"
                                    />
                                }
                                label="Re-Open"
                            />
                        </div>
                        <div style={{ flex: 0.7 }}>
                            <TextareaAutosize
                                minRows={3}
                                placeholder="Remark"
                                value={modifiedRows[issueId]?.IssuesReopenRemark || ''}
                                onChange={(event) => handleRemarkChange(event, issueId)}
                                style={{ width: '100%', height: '30px', resize: 'none', fontFamily: 'sans-serif' }} // Full width and no resizing
                                onKeyDown={(event) => {
                                    // Prevent default action if necessary
                                    if (event.key === ' ') {
                                        event.stopPropagation(); // Prevent the event from bubbling up
                                    }
                                }}
                            />
                        </div>
                    </div>
                );
            },
        },

    ];

    const historyService = [
        { field: 'Date', headerName: 'Date', renderCell: (params) => Moment(params.value).format('DD-MMM-YYYY') },
        { field: 'TotalServiceMinutes', headerName: 'Duration', width: 80 },
        { field: 'ContactPerson', headerName: 'Executive' },
        { field: 'ServiceProvided', headerName: 'Service Remarks', width: 400 },
    ];
    // Adjust the overall width of the DataGrid to ensure that the total column widths fit within the available space.


    return (
        <Stack>
            <>
                {/* <Typography variant="h5" marginLeft={3} marginBottom={2} > */}
                {/*    Issue Closure History */}
                {/* </Typography> */}
                <Stack spacing={2}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" m={1} spacing={1}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker

                                label="From Date"
                                inputFormat="dd/MMM/yyyy"
                                value={Moment(historyFromValue).format('YYYY/MM/DD')}
                                onChange={handleHistoryFrom}
                                renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                            />
                        </LocalizationProvider>

                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                                label="To Date"
                                inputFormat="dd/MMM/yyyy"
                                value={Moment(historyToValue).format('DD-MMM-yyyy')}
                                onChange={handleHistoryTo}
                                renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                            />
                        </LocalizationProvider>

                        <Stack direction={{ xs: "column", sm: "row" }} alignItems="center" justifyContent="space-between" spacing={1}>

                            <Tooltip title="Generate">

                                <Button
                                    variant="contained"
                                    size="small"
                                    onClick={onGenerateHistory}
                                ><Icon icon="ic:outline-filter-alt" width="25px" height="25px" /></Button>
                            </Tooltip >

                        </Stack>
                    </Stack>
                    <Divider />

                    {/* ----------------------------------------------------------------- */}
                    <div style={{ height: '48vh', width: '100%' }}>

                 
                            <DataGrid
                                rows={historyData}
                                density="compact"
                                columns={history}
                                getRowId={(row) => historyData.indexOf(row)}
                                components={{ Toolbar: GridToolbar }}
                                getRowHeight={() => 'auto'}
                                sx={{
                                    width: '1000px', // Set width to a larger value to enable horizontal scrolling
                            }}
                            pageSize={25} 
                            />
                        </div>

                </Stack>
                <Modal open={openModal} onClose={handleCloseModal}>
                    <Box sx={modalStyle}>
                        <IconButton
                            onClick={handleCloseModal}
                            style={{
                                position: 'absolute',
                                top: '10px',
                                right: '10px',
                                color: 'grey',
                            }}
                        >
                            <CloseIcon />
                        </IconButton>

                        <div style={{ height: '60vh', width: '100%' }}>
                            <DataGrid
                                rows={historyServiceData}
                                columns={historyService}
                                density="compact"
                                getRowId={(row) => row.id || historyServiceData.indexOf(row)}
                                components={{ Toolbar: GridToolbar }}
                                getRowHeight={() => 'auto'}
                                pageSize={25} 
                            />
                        </div>

                    </Box>
                </Modal>
                <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="center" spacing={2}>

                    <LoadingButton
                        size="medium"
                        type="submit"
                        variant="contained"
                        sx={{ color: 'white' }}
                        onClick={saveHistory }
                    >
                        Save
                    </LoadingButton>

                 

                </Stack>
            </>
        </Stack>
       
    );
}