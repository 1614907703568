import { React, useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import XLSX from 'sheetjs-style';

// Material-UI components
import {
    Card,
    IconButton,
    Stack,
    Button,
    TextField,
    Divider,
    FormControl,
    MenuItem,
    InputLabel,
    Select,
    Box,
    Tooltip,
    Menu,
    Fade,
    Typography
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { Icon } from '@iconify/react';
import * as FileSaver from 'file-saver';
import URLS from '../../../services/urls.service';
import LoadingProgressBar from '../../../theme/overrides/LoadingProgressBar';
import Page from '../../../components/Page';
import AppCache from '../../../services/AppCache';

export default function InstallationReportListing() {

    const TABLE_HEAD = [
        { field: 'ClientId', headerName: 'Client Id', flex: 1 },
        { field: 'ClientName', headerName: 'Client Name', flex: 1 },
        {
            field: 'OANumber', headerName: 'OANumber', flex: 1 
        },
        {
            field: 'ProductName', headerName: 'Product', flex: 1 
        },
        {
            field: 'IssueId', headerName: 'Issue Id', flex: 1 
        },
        {
            field: 'SiteName', headerName: 'Site Name', flex: 1 
        },
        {
            field: 'Remark', headerName: ' Remark', flex: 1 
        },

    ];
    const { enqueueSnackbar } = useSnackbar();
    const location = useLocation();
    const CurrentLoggedInUser = AppCache.GetCurrentLoggedInUser();

    const [clients, setClients] = useState('');
    const [selectClient, setSelectClient] = useState([]);
   
    const [reports, setReports] = useState([]);
    const [isLoading, setIsLoading] = useState();
    const [userId, setUserId] = useState('');
    const [userName, setUserName] = useState([]);

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClients = (event) => setClients(event.target.value);


    const columns = [];
    const rows = [];

    useEffect(() => {
        setUserId(CurrentLoggedInUser.ExecutiveId);

        setUserName(CurrentLoggedInUser.ExecutiveName);
    }, []);

    useEffect(() => {
        if (userId) {
            URLS.getClientddl({ Id: userId })
                .then((response) => setSelectClient(response.data))
                .catch(error => console.log("Error", error));
        }

    }, [userId]);

    reports.forEach((item, index) => {
        const row = { id: index + 1 };
        Object.entries(item).forEach(([key, value]) => {
            if (index === 0) {
                columns.push({
                    field: key,
                    headerName: key,
                    width: 150
                });
            }
            row[key] = value;
        });
        rows.push(row);
    })

    // Filter function
    const onFilterClick = () => {
        setIsLoading(true);

        const payload = {
           
            ClientId: clients,
            Id:userId
        };
        URLS.installationReportFilter(payload)
            .then((response) => {
                setIsLoading(false);
                setReports(response.data)
                enqueueSnackbar("Records Fetched successfully", { variant: 'success' });

            })
            .catch(error => {
                setIsLoading(false);
                setReports([]);
                enqueueSnackbar("Records not found", { variant: 'success' });

            });
    };
    console.log("reports", reports)
    // Export to Excel function
    const exportToExcel = () => {
        const fileExtension = '.xlsx';
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

        // Check if reports are available
        if (!reports || reports.length === 0) {
            console.log("No data to export.");
            return;
        }

        // Prepare headers based on response keys
        const headers = Object.keys(reports[0]);

        // Prepare the row data based on reports data
        const dataToExport = reports.map((row) => {
            return headers.map(header => row[header]); // Map each header to the corresponding row data
        });

        const dataWithHeaders = [headers, ...dataToExport]; // Combine headers with data rows
        const ws = XLSX.utils.aoa_to_sheet(dataWithHeaders); // Convert to AOA format

        const wb = { Sheets: { data: ws }, SheetNames: ['data'] }; // Create workbook

        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' }); // Write workbook to buffer
        const data = new Blob([excelBuffer], { type: fileType }); // Create a blob from the buffer

        const fileName = `${userName}_InstallationReport${fileExtension}`; // Define file name

        FileSaver.saveAs(data, fileName); // Save the file
    };
    // Reset function
    const onResetClick = () => {
        setClients('');
        setReports([]);
    };

    // Handle export dropdown open/close
    const handleExport = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);


    useEffect(() => {
        setClients('');
        setReports([]);
    }, [location.key]);



    return (

        <Page title="Installation Report Listing" >
            <Typography variant="h5" color="#b71c1c" margin={1} >
                Installation Report Listing
            </Typography>
            <Card >

                <Stack direction="row" alignItems="center" justifyContent="space-between" m={1} spacing={1}>
                    <FormControl fullWidth size="small">
                        <InputLabel InputLabelProps={{ style: { color: 'green', fontSize: '14px' } }}>
                            Clients
                        </InputLabel>
                        <Select
                            label="Clients"
                            size="small"
                            value={clients}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        backgroundColor: '#fbe9e7',
                                        maxHeight: '200px',
                                    },
                                },
                            }}
                            style={{width:'32%'} }
                            onChange={handleClients}
                        >
                            <MenuItem key={0} value={""} >Select</MenuItem>

                            {selectClient.map((client) => (
                                <MenuItem
                                    key={client.Id}
                                    value={client.Id}
                                >
                                    {client.Name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                   
                    <Stack direction={{ xs: "column", sm: "row" }} alignItems="center" justifyContent="space-between" spacing={1}>

                        <Tooltip title="Filter">

                            <Button
                                variant="contained"
                                size="small"
                                onClick={onFilterClick}
                            ><Icon icon="ic:outline-filter-alt" width="25px" height="25px" /></Button>
                        </Tooltip >
                        <Tooltip title="Reset">
                            <Button
                                variant="contained"
                                size="small"
                                onClick={onResetClick}
                            ><Icon icon="ic:twotone-restart-alt" width="25px" height="25px" /></Button>
                        </Tooltip >
                        <Tooltip title="Export">
                           
                            <Button
                                variant="contained"
                                size="small"
                                onClick={exportToExcel}// Assuming handleExport is the function for the export action
                                sx={{ marginBottom: 1 }}
                            >
                                <Icon icon="ic:baseline-arrow-downward" width="25px" height="25px" />
                            </Button>
                        </Tooltip>
                        {/* <Menu */}
                        {/*    id="fade-menu" */}
                        {/*    MenuListProps={{ */}
                        {/*        'aria-labelledby': 'fade-button', */}
                        {/*    }} */}
                        {/*    anchorEl={anchorEl} */}
                        {/*    open={open} */}
                        {/*    onClose={handleClose} */}
                        {/*    TransitionComponent={Fade} */}
                        {/* > */}
                        {/*    <MenuItem onClick={handleClose}>  <Button sx={{ color: 'black' }} size="small" onClick={exportToExcel}>Export to excel</Button></MenuItem> */}


                        {/* </Menu> */}

                    </Stack>
                </Stack>
                <Divider />

                {/* ----------------------------------------------------------------- */}

                <div style={{ height: '70vh', width: '100%' }}>
                    <DataGrid
                        rows={reports}
                        density={'compact'}
                        columns={TABLE_HEAD} 
                        getRowId={(row) => row.id || reports.indexOf(row)}
                        components={{
                            Toolbar: GridToolbar,
                        }}
                        getRowHeight={() => 'auto'}
                        
                        pageSize={25}

                    />

                </div>

            </Card>
            {isLoading && (<LoadingProgressBar sx={{ py: 6 }} />)}


        </Page>


    );
}
