import { React, useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import XLSX from 'sheetjs-style';

// Material-UI components
import {
    Card,
    IconButton,
    Stack,
    Button,
    TextField,
    Divider,
    FormControl,
    MenuItem,
    InputLabel,
    Select,
    Box,
    Tooltip,
    Menu,
    Fade,
    Typography,
    Checkbox,
    Dialog, DialogTitle, DialogContent, Modal
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { Icon } from '@iconify/react';
import * as FileSaver from 'file-saver';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import Moment from 'moment';
import ListIcon from '@mui/icons-material/List';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import URLS from '../../../services/urls.service';
import LoadingProgressBar from '../../../theme/overrides/LoadingProgressBar';
import Page from '../../../components/Page';
import AppCache from '../../../services/AppCache';


const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor: '#fbe9e7', // Set the background color to #fbe9e7
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));
const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    maxHeight: '80vh',
    overflow: 'auto',
    borderRadius: '8px',
};

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const style = {
    position: 'absolute',
    top: '50%',
    justifyContent: 'center',
    left: '50%',
    transform: 'translate(-50%, -50%)',

    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 10,
    p: 4,
};

export default function IRBillLinking() {

    const TABLE_HEAD = [
        {
            field: 'CompletedDate', headerName: 'Completed Date', flex: 1 
        },
        {
            field: 'PartyName', headerName: 'Party Name', flex: 1
        },
        {
            field: 'ProductName', headerName: 'Product Name', flex: 1
        },
        {
            field: 'billingName', headerName: 'Billing Name', flex: 1
        },
    ];
   
    const billData = [
        {
            field: 'CompletedDate', headerName: 'Completed Date', flex: 1
        },
        {
            field: 'PartyName', headerName: 'Pary Name', flex: 1
        },
        {
            field: 'ProductName', headerName: 'Product Name', flex: 1
        },
       
    ];
  
    const { enqueueSnackbar } = useSnackbar();
    const location = useLocation();
    const CurrentLoggedInUser = AppCache.GetCurrentLoggedInUser();

    const [clients, setClients] = useState('');
    const [selectClient, setSelectClient] = useState([]);

    const [billNumber, setBillNumber] = useState('');

    const [reports, setReports] = useState([]);
    const [billNoData, setBillNoData] = useState([]);
    const [isLoading, setIsLoading] = useState();
    const [userId, setUserId] = useState([]);
    const [userName, setUserName] = useState([]);

    const [selectedRows, setSelectedRows] = useState([]);

    const [panelData, setPanelData] = useState([]);
    const [billDataDropdown, setBillDataDropdown] = useState('');
    const [selectedSAPLProductId, setSelectedSAPLProductId] = useState("");
    const [productId, setProductId] = useState("");
    const [selectedUnMappedBillQuantity, setSelectedUnMappedBillQuantity] = useState("");
    const [selectedBillingName, setSelectedBillingName] = useState("");

    const [open, setOpen] = useState(false);


    const handleClients = (event) => setClients(event.target.value);
    const handleBillNumber = (event) => {

        setBillNumber(event.target.value); // Keep as array of objects
    };

    // const handleBillDropdown = (event) => setBillDataDropdown(event.target.value);

    const handleBillDropdown = (event) => {
        const selectedId = event.target.value;
        setBillDataDropdown(selectedId);

        // Find the corresponding SAPLProductId
        const selectedPanel = panelData.find(panel => panel.Id === selectedId);
        if (selectedPanel) {
            setSelectedSAPLProductId(selectedPanel.ProductId);
            setSelectedBillingName(selectedPanel.TallyProductName.replace(/\(.*\)/, '').trim());
            setSelectedUnMappedBillQuantity(selectedPanel.UnMappedBillQuantity)
            setProductId(selectedPanel.SAPLProductId)
        } else {
            setSelectedSAPLProductId("");
            setSelectedBillingName("");
            setSelectedUnMappedBillQuantity("");
            setProductId("");
        }
    };
    // Filter function
    const onFilterClick = () => {
        if (clients.length === 0) {
            enqueueSnackbar("Please select Client");
            return;
        }
        if (billNumber.length === 0) {
            enqueueSnackbar("Please select Billing Number");
            return;
        }
        setIsLoading(true);

        const payload = {
            Id: clients,
            BillNumber: billNumber,

        };  

        URLS.getTallyFilterData(payload)
            .then((response) => {
                setIsLoading(false);
                setPanelData(response.data)
                URLS.getOAInstallationReportBillingFilter({ ClientId: clients })
                    .then((response) => {
                        setIsLoading(false);
                        setReports(Array.isArray(response.data) ? response.data : []);
                        enqueueSnackbar("Records Fetched successfully", { variant: 'success' });
                    })
                    .catch(error => {
                        setIsLoading(false);
                        setReports([]);

                        enqueueSnackbar("Records not found", { variant: 'success' });
                    });
            })
            .catch(error => {
                setIsLoading(false);
                setPanelData([]);
                setReports([]);

            });
        
    };

    const handleSelectionChange = (selectionModel) => {
        if (selectionModel && selectionModel.length > 0) {
            // Update the billingName for all selected reports
            const updatedReports = reports.map(report =>
                selectionModel.includes(report.Id)
                    ? { ...report, billingName: selectedBillingName || "Default Billing" }
                    : { ...report, billingName: "" }
            );

            setReports(updatedReports);  // Update reports state

            // Sync selected rows from the updated reports
            const updatedSelectedRows = updatedReports.filter(report => selectionModel.includes(report.Id));
            setSelectedRows(updatedSelectedRows);  // Ensure selected rows reflect the updated data
        } else {
            // Clear the billingName for all reports when no rows are selected
            const clearedReports = reports.map(report => ({
                ...report,
                billingName: ""
            }));

            setReports(clearedReports);
            setSelectedRows([]);
        }
    };


    const handleOpen = () => {
        setOpen(true);  // Open the modal
        if (billNumber) {
            URLS.getBilledData({ BillNumber: billNumber })
                .then((response) => {
                    setBillNoData(response.data);  // Set the API response data
                })
                .catch((error) => {
                    console.log("Error", error);
                });
        }
    };
    const handleClose = () => setOpen(false);
   
    const handleSave = () => {
        if (selectedRows.length === 0) {
            enqueueSnackbar("Please select Rows");
            return;
        }
        if (billNumber.length === 0) {
            enqueueSnackbar("Please select Billing Number");
            return;
        }
        const tallyQuantity= panelData.map(item => ({
            ProductId: item.SAPLProductId, // Assuming 'SAPLProductId' is the field for ProductId
            Quantity: item.UnMappedBillQuantity // Assuming 'UnMappedBillQuantity' is the field for the quantity
        }));
        const slectedIds = selectedRows.map(item => ({
            Id: item.Id,
            ProductId: item.ProductId,
        }));
        
        const payload = {
            BillNo: billNumber,
            OaData: slectedIds,
            TallyData: tallyQuantity,
            ProductId: selectedSAPLProductId,
            Quantity: selectedUnMappedBillQuantity,
            TallyProductId: productId
        };
        
        const filterPayload = {
            Id: clients,
            BillNumber: billNumber,
        };

        console.log("payload", payload)
        URLS.updateOAInstallationReportMasterBillNo(payload)
            .then((response) => {
                setIsLoading(false);
               
                URLS.getTallyFilterData(filterPayload)
                    .then((response) => {
                        setIsLoading(false);
                        setPanelData(response.data)
                        URLS.getOAInstallationReportBillingFilter({ ClientId: clients })
                            .then((response) => {
                                setIsLoading(false);
                                setReports(Array.isArray(response.data) ? response.data : []);
                            })
                            .catch(error => {
                                setIsLoading(false);
                                setReports([]);

                            });
                    })
                    .catch(error => {
                        setIsLoading(false);
                        setPanelData([]);
                        setReports([]);

                    });

                enqueueSnackbar("Data saved successfully", { variant: 'success' });

            })
            .catch((error) => {
                setIsLoading(false);
                enqueueSnackbar("Bill number is not assigned for this product", { variant: 'error' });
            })
    }
    // Reset function
    const onResetClick = () => {
        setClients('');
        setReports([]);
        setBillNumber('');
        setPanelData([]);
        setSelectedSAPLProductId("");
        setSelectedBillingName("");
        setSelectedUnMappedBillQuantity("");
        setProductId("");
    };

    useEffect(() => {
        setUserId(CurrentLoggedInUser.ExecutiveId);
        setUserName(CurrentLoggedInUser.ExecutiveName);
    }, []);


    useEffect(() => {
            URLS.getOAClient()
                .then((response) => setSelectClient(response.data))
                .catch(error => console.log("Error", error));
        if (billNumber) {
            URLS.getBilledData({ BillNumber: billNumber })
                .then((response) => setBillNoData(response.data))
                .catch(error => console.log("Error", error));

        }
        
    }, [billNumber]);

    useEffect(() => {
        setClients('');
        setReports([]);
        setBillNumber('');
        setPanelData([]);
        setSelectedSAPLProductId("");
        setSelectedBillingName("");
        setSelectedUnMappedBillQuantity("");
        setProductId("");
    }, [location.key]);

    console.log("panel data", panelData, selectedRows, billDataDropdown, selectedBillingName,selectedSAPLProductId)


    return (

        <Page title="IR Bill Linking" >
            <Typography variant="h5" color="#b71c1c" margin={1} >
                IR Bill Linking
            </Typography>
            <Card >
                <Stack direction="row" alignItems="center" justifyContent="space-between" m={1} spacing={2} mb={2 }>
                    {/* Clients Dropdown */}
                    <FormControl size="small" sx={{ flex: 1 }}>
                        <InputLabel InputLabelProps={{ style: { color: 'green', fontSize: '14px' } }}>
                            Clients
                        </InputLabel>
                        <Select
                            label="Clients"
                            value={clients}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        backgroundColor: '#fbe9e7',
                                        maxHeight: '200px',
                                        width: '200px',
                                    },
                                },
                            }}
                            onChange={handleClients}
                        >
                            <MenuItem key={0} value={""} >Select</MenuItem>

                            {selectClient.map((client) => (
                                <MenuItem key={client.ContactId} value={client.ContactId}>
                                    {client.Name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                        <TextField
                            fullWidth
                            label="Bill Number"
                            size="small"
                            value={billNumber}
                            onChange={handleBillNumber}
                            sx={{ flex: 1 }}
                    />
                    <Tooltip title="Filter">
                        <Button variant="contained" size="small" onClick={onFilterClick}>
                            <Icon icon="ic:outline-filter-alt" width="25px" height="25px" />
                        </Button>
                    </Tooltip>
                    <FormControl size="small" sx={{ flex: 1 }}>
                        <InputLabel InputLabelProps={{ style: { color: 'green', fontSize: '14px' } }}>
                            Billing Data
                        </InputLabel>
                        <Select
                            label="Billing Data"
                            value={billDataDropdown}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        backgroundColor: '#fbe9e7',
                                        maxHeight: '200px',
                                        width: '200px',
                                    },
                                },
                            }}
                            onChange={handleBillDropdown}
                        >
                            <MenuItem key={0} value={""} >Select</MenuItem>

                            {panelData.map((panel) => (
                                <MenuItem key={panel.Id} value={panel.Id}>
                                    {panel.TallyProductName}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Tooltip title="Save">
                        <Button variant="contained" size="small" onClick={handleSave}>
                            <Icon icon="ic:outline-save" width="25px" height="25px" />
                        </Button>
                    </Tooltip>
                    <Tooltip title="Reset">
                        <Button variant="contained" size="small" onClick={onResetClick}>
                            <Icon icon="ic:twotone-restart-alt" width="25px" height="25px" />
                        </Button>
                    </Tooltip>
                    <Tooltip title="Reset">
                        <Button variant="contained" size="small" onClick={handleOpen}>
                            <Icon icon="ic:outline-list" width="25px" height="25px" />
                        </Button>
                    </Tooltip>
                    <Modal open={open} onClose={handleClose}>
                        <Box sx={modalStyle}>
                            <IconButton
                                onClick={handleClose}
                                style={{
                                    position: 'absolute',
                                    top: '10px',
                                    right: '10px',
                                    color: 'grey',
                                }}
                            >
                                <CloseIcon />
                            </IconButton>

                            {billNoData ? (
                                <div style={{ height: '60vh', width: '100%' }}>
                                    <DataGrid
                                        rows={billNoData}
                                        density={'compact'}
                                        columns={billData}
                                        getRowId={(row) => row.Id || billNoData.indexOf(row)} // Use `Id` here instead of `id`
                                        components={{
                                            Toolbar: GridToolbar,
                                        }}
                                        getRowHeight={() => 'auto'}

                                        pageSize={25}
                                    />
                                </div>

                            ) : (
                                <Typography>Loading...</Typography> // Show loading message until data is fetched
                            )}

                        </Box>
                    </Modal>
                   
                </Stack>

                <Divider />
                
                <div style={{ height: '65vh', width: '100%' }}>
                    <DataGrid
                        rows={reports}
                        density={'compact'}
                        columns={TABLE_HEAD}
                        getRowId={(row) => row.Id} // Use `Id` here instead of `id`
                        components={{
                            Toolbar: GridToolbar,
                        }}
                        getRowHeight={() => 'auto'}
                        checkboxSelection
                        pageSize={25}
                        onSelectionModelChange={handleSelectionChange}
                    />
                </div>

            </Card>
            {isLoading && (<LoadingProgressBar sx={{ py: 6 }} />)}


        </Page>


    );
}
