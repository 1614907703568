import * as Yup from 'yup';
import * as React from 'react';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';

// material
import { Tooltip, Stack, TextField, Typography, FormGroup, TextareaAutosize, FormControl, FormLabel, InputLabel, Select, MenuItem, Button, Grid, RadioGroup, Radio, FormHelperText, IconButton } from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import TimePicker from '@mui/lab/TimePicker';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Moment from 'moment';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { format } from 'date-fns';
import Iconify from '../../Iconify';
import URLS from '../../../services/urls.service';
import AppCache from '../../../services/AppCache';
import { handleError } from '../../Common/Utils';
import ModalPopUp from '../../Common/ModalPopUp';
// -----------------------------------------------------------------------------------------------------


const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor: '#fbe9e7', // Set the background color to #fbe9e7
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));


const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const style = {
    position: 'absolute',
    top: '50%',
    justifyContent: 'center',
    left: '50%',
    transform: 'translate(-50%, -50%)',

    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 10,
    p: 4,
};

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});
export default function ServiceRequestBDTrackerDetails({ loadTrackerData, tracker, trackerMasterId }) {

        const { enqueueSnackbar } = useSnackbar();
        const navigate = useNavigate();
        // const productId = proMaster.ID;
        console.log("trackerId details>>", trackerMasterId, tracker,"loadTrackerData", loadTrackerData)

        const [fieldValues, setFieldValues] = useState({});
        const [requestFile, setRequestFile] = useState([]);
        const [base64Document, setBase64Document] = useState([]); // New state for Base64 string
        const [validationErrors, setValidationErrors] = useState({});

        // executive ID
        const CurrentLoggedInUser = AppCache.GetCurrentLoggedInUser();
        const [userId, setUserId] = useState([]);
        const [userName, setUserName] = useState('');

        const [openModalForCancel, setOpenModalCancel] = useState(false);
        // Modal
        const message = {
            forCancel: 'Do you want to cancel ? ',
        };
        const forCancelConfirm = (value) => {
            navigate('/dashboard/servicerequest/servicerequesttracker', { replace: true });
            setOpenModalCancel(false);
        };

        const handelCancelClick = () => {
            setOpenModalCancel(true);
        };

        const handleField = (sequence, value) => {
            // Update the field value in the state
            const finalValue = value === "@LoginUser" ? userName : value;

            setFieldValues(prevState => ({
                ...prevState,
                [sequence]: finalValue
            }));

            // Clear validation error for the field if value is entered
            if (validationErrors[sequence] && value.trim() !== '') {
                setValidationErrors(prevErrors => {
                    const updatedErrors = { ...prevErrors };
                    delete updatedErrors[sequence]; // Remove error for the specific field
                    return updatedErrors;
                });
            }
        };
  
        const handleSelectFile = (event) => {
            const selectedFiles = Array.from(event.target.files);
            const imagesArray = selectedFiles.map((file) => URL.createObjectURL(file));

            setRequestFile((prevImages) => [...prevImages, ...selectedFiles]);
            setBase64Document((prevFiles) => [...prevFiles, ...imagesArray]);

            event.target.value = ""; // Clear the file input value
            console.log("selectedFiles", selectedFiles);
        };

        const removeFile = (index) => {
            setRequestFile((prevFiles) => prevFiles.filter((_, i) => i !== index)); // Remove file by index
        };

        const renderField = (fields) => {
            const { UIControlKeyId, DisplayName, Sequence, DefaultValue, IsEdit, ColumnName ,IsCompulsory} = fields;

            // Get the preselected value from tracker[0] using the ColumnName
            const trackerData = tracker.DataTable?.[0] ||[]; // Ensure tracker[0] exists
            console.log("trackerData[ColumnName]:", trackerData[ColumnName]);

            const resolvedDefaultValue = DefaultValue === "@LoginUser" ? userName : DefaultValue;
            console.log("resolvedDefaultValue:", resolvedDefaultValue);
            const preSelectedValue = trackerData[ColumnName] || resolvedDefaultValue || '';
            console.log("preSelectedValue:", preSelectedValue);

            if (fieldValues[Sequence] === undefined && preSelectedValue) {
                console.log("Calling handleField with Sequence:", Sequence, "and value:", preSelectedValue);
                handleField(Sequence, preSelectedValue);
            }

            console.log("fieldValues[Sequence]:", fieldValues[Sequence]);
            // Error condition: if there is no field value and the preselected value is cleared
            const hasError = IsCompulsory && (!fieldValues[Sequence] || fieldValues[Sequence].trim() === '');
            const errorMessage = hasError ? validationErrors[Sequence] : '';

            switch (UIControlKeyId) {
                case 1: // Label
                  {  return (
                        <FormControl fullWidth margin="normal">
                            <InputLabel shrink>{DisplayName}</InputLabel>
                            <div>{preSelectedValue}</div>
                        </FormControl>
                    );
    }
                case 2: // TextField
                    {return (
                        <TextField
                            label={DisplayName}
                            fullWidth
                            size="small"
                            onChange={(e) => handleField(fields.Sequence, e.target.value)}
                            value={fieldValues[fields.Sequence] ?? preSelectedValue ?? ''}
                            disabled={!IsEdit}
                            error={!!errorMessage}
                            helperText={errorMessage}
                        />
                    );
    }
                case 3: // Dropdown/Select
                    {
                        let options = [];

                        if (fields.DataValues.includes("Id:")) {
                            const regex = /Id:\s*(\d+),\s*Name:\s*([\w\s]+)/g;
                            const matches = Array.from(fields.DataValues.matchAll(regex));
                            options = matches.map(match => ({
                                id: parseInt(match[1], 10),
                                name: match[2]
                            }));
                        } else {
                            const dataValuesMatch = fields.DataValues.match(/\[([^[\]]+)\](?=[^[]*$)/);
                            options = dataValuesMatch
                                ? dataValuesMatch[1].split('|').map(value => ({ id: value, name: value }))
                                : [];
                        }

                        return (
                            <FormControl fullWidth
                                size="small"
                                error={!!errorMessage}
                            >
                                <InputLabel>{DisplayName}</InputLabel>
                                <Select
                                    label={DisplayName}
                                    onChange={(e) => handleField(fields.Sequence, e.target.value)}
                                    value={fieldValues[fields.Sequence] || preSelectedValue}
                                    MenuProps={{
                                        PaperProps: {
                                            sx: {
                                                backgroundColor: '#fbe9e7',
                                                maxHeight: '200px',
                                                width: '200px',
                                            },
                                        },
                                    }}
                                    error={!!errorMessage} 
                                    disabled={!IsEdit} 
                                >
                                    {!IsCompulsory && <MenuItem key={0} value="">Select</MenuItem>}
                                    {options.map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {errorMessage && <FormHelperText style={{ color: 'red' }}>
                                    {errorMessage}
                                </FormHelperText>}
                            </FormControl>
                        );
                    }
                case 4: // Radio Button
                    {
                        let radioOptions = [];
                        const defaultRadioValue = preSelectedValue || 'No';

                        if (fields.DataValues) {
                            const cleanedDataValues = fields.DataValues
                                .replace(/[{}]/g, '')
                                .split(',')
                                .map(option => option.trim())
                                .filter(option => option.length > 0);

                            cleanedDataValues.forEach(option => {
                                if (option.includes('No')) radioOptions.push('No');
                                if (option.includes('Yes')) radioOptions.push('Yes');
                                if (option === '[Yes|No]') radioOptions = ['Yes', 'No'];
                            });
                        }

                        return (
                            <FormControl component="fieldset" error={!!errorMessage}>
                                <FormLabel component="legend">{DisplayName}</FormLabel>
                                <RadioGroup
                                    row
                                    value={fieldValues[fields.Sequence] || defaultRadioValue}
                                    onChange={(e) => handleField(fields.Sequence, e.target.value)}
                                    sx={{ flexDirection: 'row' }}
                                    disabled={!IsEdit} 
                                >
                                    {radioOptions.map((option, index) => (
                                        <FormControlLabel
                                            key={`${fields.QuestionId}-${index}`}
                                            value={option}
                                            control={<Radio />}
                                            label={option}
                                            sx={{ marginRight: 2 }}
                                        />
                                    ))}
                                </RadioGroup>
                                {errorMessage && (
                                    <FormHelperText style={{ color: 'red' }}>
                                        {errorMessage}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        );

                    }
                case 5: // Checkbox
                    {
                        const checkboxValue = fieldValues[fields.Sequence] ?? (preSelectedValue === 'true' || preSelectedValue === true);

                        return (
                            <FormControl error={!!errorMessage} component="fieldset">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={checkboxValue}
                                            onChange={(e) => handleField(fields.Sequence, e.target.checked)}
                                            color="primary"
                                            disabled={!IsEdit}
                                        />
                                    }
                                    label={DisplayName}
                                />
                                {errorMessage && (
                                    <FormHelperText style={{ color: 'red' }}>
                                        {errorMessage}
                                    </FormHelperText>
                                )}
                            </FormControl>

                        );

                    }
                case 6: // CheckBoxList
                    {
                        // Parse DataValues for checkbox options
                        let checkboxOptions = [];
                        const selectedValues = fieldValues[fields.Sequence] || preSelectedValue || '';

                        if (fields.DataValues) {
                            // Extract checkbox options from DataValues (e.g., "Id: 1, Name: Option1|Id: 2, Name: Option2")
                            const regex = /Id:\s*(\d+),\s*Name:\s*([\w\s]+)/g;
                            const matches = Array.from(fields.DataValues.matchAll(regex));
                            checkboxOptions = matches.map(match => ({
                                id: parseInt(match[1], 10),
                                name: match[2],
                                isChecked: selectedValues.includes(match[2]) // Check if the option is pre-selected
                            }));
                        }

                        // Handle change event for checkbox list
                        const handleCheckboxChange = (optionName, isChecked) => {
                            const updatedValues = selectedValues.split(',').filter(v => v); // Split and filter empty values
                            if (isChecked) {
                                // Add to the selected values if checked
                                if (!updatedValues.includes(optionName)) {
                                    updatedValues.push(optionName);
                                }
                            } else {
                                // Remove from the selected values if unchecked
                                const index = updatedValues.indexOf(optionName);
                                if (index > -1) {
                                    updatedValues.splice(index, 1);
                                }
                            }

                            // Join updated values back to a comma-separated string
                            handleField(fields.Sequence, updatedValues.join(','));
                        };

                        return (
                            <FormControl
                                component="fieldset"
                                error={!!errorMessage} // Error state
                            >
                                <FormLabel component="legend">{DisplayName}</FormLabel>
                                <FormGroup>
                                    {checkboxOptions.map((option) => (
                                        <FormControlLabel
                                            key={option.id}
                                            control={
                                                <Checkbox
                                                    checked={selectedValues.split(',').includes(option.name)}
                                                    onChange={(e) => handleCheckboxChange(option.name, e.target.checked)}
                                                    color="primary"
                                                    disabled={!IsEdit}
                                                />
                                            }
                                            label={option.name}
                                        />
                                    ))}
                                </FormGroup>
                                {/* Error message */}
                                {errorMessage && (
                                    <FormHelperText style={{ color: 'red' }}>
                                        {errorMessage}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        );
                    }
                case 7: // Date Picker
                    {
                        return (
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDatePicker
                                    label={DisplayName}
                                    inputFormat="dd/MMM/yyyy"
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            fullWidth
                                            size="small"
                                            error={!!errorMessage} // Single source of error
                                            helperText={errorMessage} // Use only this for error message
                                        />
                                    )}
                                    onChange={(date) => {
                                        // Format the date or set to null if invalid
                                        const formattedDate = date ? format(date, 'dd-MMM-yyyy') : null;
                                        handleField(fields.Sequence, formattedDate);

                                        // Validate the date when selected
                                        if (IsCompulsory && !formattedDate) {
                                            validationErrors[fields.Sequence] = 'This field is required.';
                                        } else {
                                            validationErrors[fields.Sequence] = ''; // Reset error
                                        }
                                    }}
                                    value={fieldValues[fields.Sequence] || preSelectedValue || null} // Default or system date
                                    disabled={!IsEdit}
                                />
                            </LocalizationProvider>
                        );
                    }


                case 8: // Textarea
                    {
                        return (
                            <FormControl fullWidth margin="normal" error={!!errorMessage}>
                                <TextareaAutosize
                                    placeholder={DisplayName}
                                    minRows={3}
                                    onChange={(e) => handleField(fields.Sequence, e.target.value)}
                                    value={fieldValues[fields.Sequence] ?? preSelectedValue ?? ''}
                                    disabled={!IsEdit}
                                    style={{
                                        width: "100%",
                                        height: "75px",
                                        fontFamily: "Tahoma",
                                        fontSize: "12px",
                                        borderColor: errorMessage ? 'red' : '#c4c4c4', // Add red border if there's an error
                                        padding: '10px',
                                    }}
                                />
                                {/* Display error message */}
                                {errorMessage && (
                                    <FormHelperText style={{ color: 'red' }}>
                                        {errorMessage}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        );
                    }


                default:
                    return null;
            }
    };

    console.log("requestfile", requestFile, base64Document)
    useEffect(() => {
        setUserId(CurrentLoggedInUser.ExecutiveId);
        setUserName(CurrentLoggedInUser.ExecutiveName);
    }, []);

    const handleDownload = (file, index) => {
        if (tracker.FileUrl && tracker.FileUrl.length > 0) {
            console.log("Downloading specific file...");

            // Use the specific URL for the file clicked
            const fileUrl = tracker.FileUrl[index]; // Use the corresponding URL from tracker.FileUrl
            const a = document.createElement("a");
            a.href = fileUrl;
            a.download = file.name ? file.name : `file_${index + 1}`; // Use file.name if available, otherwise default name
            a.click();
            console.log(`Downloading: ${fileUrl}`);
        } else {
            console.log("No FileUrl found, downloading as blob...");

            // Fallback to downloading as a blob if no FileUrl exists
            const fileUrl = URL.createObjectURL(file);
            const a = document.createElement("a");
            a.href = fileUrl;
            a.download = file.name; // Use the file name for download
            a.click();
            URL.revokeObjectURL(fileUrl);
        }
    };

    useEffect(() => {
        setUserId(CurrentLoggedInUser.ExecutiveId);
        setUserName(CurrentLoggedInUser.ExecutiveName);

        // Preselect file from RefrenceDocument if available
        const referenceDocument = tracker.DataTable?.[0].RefrenceDocument;
        if (referenceDocument) {
            // Split the reference document string by comma to get individual file names
            const fileNames = referenceDocument.split(',');

            // Initialize arrays for requestFile and base64Document
            const filesArray = [];
            const fileUrls = [];

            fileNames.forEach((fileName) => {
                // Create a File object for each file name
                const preselectedFile = new File([], fileName.trim(), {
                    type: 'text/plain', // Provide the MIME type if known (adjust based on file type)
                });

                // Add the File object to the files array
                filesArray.push(preselectedFile);

                // Create a URL for the file and add it to base64Document for preview (if needed)
                const preselectedFileUrl = URL.createObjectURL(preselectedFile);
                fileUrls.push(preselectedFileUrl);
            });

            // Set preselected files in the requestFile and base64Document states
            setRequestFile((prevFiles) => [...prevFiles, ...filesArray]);
            setBase64Document((prevFiles) => [...prevFiles, ...fileUrls]);
        }
    }, [tracker]);

    useEffect(() => {
        // Check for empty fields and update validation errors
        const newValidationErrors = {};
        loadTrackerData.forEach(field => {
            const value = fieldValues[field.Sequence];
            if (field.IsCompulsory && (!value || value.trim() === '')) {
                newValidationErrors[field.Sequence] = `${field.DisplayName} is required`;
            }
        });
        if (requestFile.length === 0) {
            newValidationErrors.files = 'At least one file must be selected';
        } else {
            // Clear the error when files are selected
            delete newValidationErrors.files;
        }
        // Update validation errors state
        setValidationErrors(newValidationErrors);

    }, [fieldValues, requestFile]); // Listen for changes in field values

        const formik = useFormik({
            initialValues: {

            },

            onSubmit: (values, actions) => {
               
                const currentErrors = {};
            
                const QuestionAnswersData = loadTrackerData.map(field => {
                    const fieldValue = fieldValues[field.Sequence];
                    const preSelectedValue = tracker.DataTable?.[0][field.ColumnName] || field.DefaultValue || '';
                    const resolvedDefaultValue = preSelectedValue === "@LoginUser" ? userName : preSelectedValue;

                    // Check if field is required and not filled
                    const showError = field.IsCompulsory && (!fieldValue || fieldValue === '' || !preSelectedValue);
                    // const isFieldEmpty = field.IsCompulsory && (!fieldValue || fieldValue.trim() === '');

                    // if (isFieldEmpty) {
                    //    currentErrors[field.Sequence] = `${field.DisplayName} is required`;
                    // }

                    // if (showError) {
                    //    validationErrors[field.Sequence] = `${field.DisplayName} is required`; // Store the error message for the specific field
                    // }

                    let finalValue;
                    if (field.UIControlKeyId === 7) { // Check if it's a DatePicker
                        finalValue = fieldValue || resolvedDefaultValue || format(new Date(), 'dd-MMM-yyyy'); // Default to system date if empty
                    } else {
                        finalValue = fieldValue || field.Answers || resolvedDefaultValue || ''; // Use value or pre-selected/default
                    }

                    return {
                        ColumnName: field.ColumnName,
                        Value: finalValue,
                    };
                });

                if (JSON.stringify(currentErrors) !== JSON.stringify(validationErrors)) {
                    setValidationErrors(currentErrors);
                }

                // **Stop form submission if there are validation errors**
                if (Object.keys(currentErrors).length > 0) {
                    console.log("Form has validation errors:", currentErrors);
                    return;
                }

                // Proceed with form submission if no errors
                const formData = new FormData();
                formData.append("Id", trackerMasterId || 0);
                formData.append("LoginId", userId);
                formData.append("ServiceRequestId", tracker.DataTable?.[0].ServiceRequestId || 0);
                formData.append("Request", "");
                formData.append("ServiceRequestMappingColumn", "");
                formData.append("RefrenceDocument", "");
                formData.append("TrackerMasterId", trackerMasterId);

                for (let i = 0; i < requestFile.length; i += 1) {
                    formData.append("RequestDocument", requestFile[i]);
                }

                QuestionAnswersData.forEach((item, index) => {
                    formData.append(`TrackerData[${index}].ColumnName`, item.ColumnName);
                    formData.append(`TrackerData[${index}].Value`, item.Value);
                });

                URLS.saveIssueTrackerData(formData)
                    .then((response) => {
                        if (response.data) {
                            enqueueSnackbar("Record saved successfully", { variant: 'success' });
                            navigate('/dashboard/servicerequest/servicerequesttracker', { replace: true });
                        }
                    })
                    .catch(error => {
                        enqueueSnackbar("Record not saved", { variant: 'error' });
                    });

                console.log("payload", formData);
            },

        });
        const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

        return (
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Stack spacing={4}>
                      
                        <Grid container spacing={2}>
                            {loadTrackerData.map((item, index) => (
                                // Check if the current item's UIControlKeyId is 8 (textarea control)
                                item.UIControlKeyId === 8 ? (
                                    <Grid item xs={12} key={index}>
                                        {renderField(item)}
                                    </Grid>
                                ) : (
                                    <Grid item xs={4} key={index}>
                                        {renderField(item)}
                                    </Grid>
                                )
                            ))}
                        </Grid>
                        
                        <Stack direction="row" alignItems="center" spacing={2}>
                            <Button
                                component="label"
                                variant="contained"
                                tabIndex={-1}
                                sx={{
                                    height: 25,
                                    width: 130,
                                    borderRadius: 1,
                                    fontWeight: 500,
                                    ":hover": { backgroundColor: "#9e9e9e" },
                                    backgroundColor: "#e0e0e0",
                                    color: "#212121",
                                    boxShadow: "none",
                                }}
                            >
                                Choose Files
                                <VisuallyHiddenInput type="file" onChange={(e) => handleSelectFile(e)} multiple />
                            </Button>

                            {/* Validation error display next to the Choose Files button */}
                            {/* {validationErrors.files && ( */}
                            {/*    <Typography variant="body2" color="error" sx={{ marginTop: 1 }}> */}
                            {/*        {validationErrors.files} */}
                            {/*    </Typography> */}
                            {/* )} */}
                            {/* {!validationErrors.files && ( */}
                                <Stack direction="row" alignItems="center" spacing={1} flexWrap="wrap" sx={{ maxWidth: 'calc(100% - 150px)' }}>
                                    {requestFile.map((file, index) => (
                                        <Stack
                                            key={index}
                                            direction="row"
                                            alignItems="center"
                                            sx={{
                                                backgroundColor: '#f5f5f5',
                                                borderRadius: 1,
                                                padding: '0 8px',
                                                marginRight: 1,
                                                maxWidth: 200, // Limit width of each file item
                                                overflow: 'hidden'
                                            }}
                                        >
                                            {/* Display the file name as a clickable link */}
                                            <a
                                                href="#"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    handleDownload(file, index); // Trigger file download on click
                                                }}
                                                style={{
                                                    textDecoration: 'none',
                                                    color: '#1976d2',
                                                    fontSize: '14px',
                                                }}
                                            >
                                                <Typography variant="body2" noWrap sx={{ maxWidth: 150 }}>
                                                    {file.name}
                                                </Typography>
                                            </a>

                                            <IconButton size="small" onClick={() => removeFile(index)} sx={{ padding: 0 }}>
                                                <Iconify icon="mdi:close" />
                                            </IconButton>
                                        </Stack>
                                    ))}
                                </Stack>
                            {/* )} */}
                        </Stack>
                        <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="center" spacing={2} mt={4}>
                            <LoadingButton
                                size="medium"
                                type="submit"
                                variant="contained"
                                sx={{ color: 'white' }}
                            >
                                Save
                            </LoadingButton>
                           
                            <Button
                                sx={{ color: 'white' }}
                                onClick={handelCancelClick}
                                size="medium"
                                variant="contained"
                                color="primary"
                            >
                                Cancel
                            </Button>
                        </Stack>
                    </Stack>

                    {openModalForCancel && (
                        <ModalPopUp openCloseValue={setOpenModalCancel} msg={message.forCancel} confirm={forCancelConfirm} />
                    )}
                </Form>
            </FormikProvider>
        );

}