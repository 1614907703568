import { React, useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import XLSX from 'sheetjs-style';

// Material-UI components
import {
    Card,
    IconButton,
    Stack,
    Button,
    TextField,
    Divider,
    FormControl,
    MenuItem,
    InputLabel,
    Select,
    Box,
    Tooltip,
    Menu,
    Fade,
    Typography,
    Checkbox,
    ListItemText,
    Autocomplete
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { Icon } from '@iconify/react';
import * as FileSaver from 'file-saver';
import URLS from '../../../services/urls.service';
import LoadingProgressBar from '../../../theme/overrides/LoadingProgressBar';
import Page from '../../../components/Page';
import AppCache from '../../../services/AppCache';

export default function InstallationApproval() {

    const TABLE_HEAD = [
        { field: 'ClientName', headerName: 'Client Name', flex: 1 },
        {
            field: 'Distributor', headerName: 'Distributor', flex: 1
        },
        {
            field: 'InstallationDate', headerName: 'Installation Date', flex: 1
        },

    ];
    const { enqueueSnackbar } = useSnackbar();
    const location = useLocation();
    const CurrentLoggedInUser = AppCache.GetCurrentLoggedInUser();

    const [clients, setClients] = useState('');
    const [selectClient, setSelectClient] = useState([]);

    const [monthYear, setMonthYear] = useState([]);
    const [selectMonthYear, setSelectMonthYear] = useState([]);

    const [billingMonth, setBillingMonth] = useState('');
    const [selectBillingMonth, setSelectBillingMonth] = useState([]);

    const [reports, setReports] = useState([]);
    const [isLoading, setIsLoading] = useState();
    const [userId, setUserId] = useState([]);
    const [userName, setUserName] = useState([]);

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [selectedRows, setSelectedRows] = useState([]);


    // Filter function
    const onFilterClick = () => {
        setIsLoading(true);
        const formattedMonths = monthYear.map(item => item.Month).join(", ");

        const payload = {
            Month: formattedMonths,
            ClientId: clients,
            Id:userId
        };

        URLS.filterInstallationApprovalData(payload)
            .then((response) => {
                setIsLoading(false);
                setReports(Array.isArray(response.data) ? response.data : []);
                enqueueSnackbar("Records Fetched successfully", { variant: 'success' });

            })
            .catch(error => {
                setIsLoading(false);
                setReports([]);
                enqueueSnackbar("Records not found", { variant: 'success' });

            });
    };

    const handleClients = (event) => setClients(event.target.value);
    const handleMonthYear = (event, newValue) => {
        setMonthYear(newValue); // Keep as array of objects
    };


    const handleBillingMonth = (event) => setBillingMonth(event.target.value);

    const columns = [];
    const rows = [];

    if (Array.isArray(reports)) {
        reports.forEach((item, index) => {
            const row = { id: index + 1 };
            Object.entries(item).forEach(([key, value]) => {
                if (index === 0) {
                    columns.push({
                        field: key,
                        headerName: key,
                        width: 150,
                    });
                }
                row[key] = value;
            });
            rows.push(row);
        });
    }

    console.log("month>>>>>>>>", reports, selectedRows)
    // Export to Excel function
    const exportToExcel = () => {
        const fileExtension = '.xlsx';
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

        // Check if reports are available
        if (!reports || reports.length === 0) {
            console.log("No data to export.");
            return;
        }

        // Prepare headers based on response keys
        const headers = Object.keys(reports[0]);

        // Prepare the row data based on reports data
        const dataToExport = reports.map((row) => {
            return headers.map(header => row[header]); // Map each header to the corresponding row data
        });

        const dataWithHeaders = [headers, ...dataToExport]; // Combine headers with data rows
        const ws = XLSX.utils.aoa_to_sheet(dataWithHeaders); // Convert to AOA format

        const wb = { Sheets: { data: ws }, SheetNames: ['data'] }; // Create workbook

        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' }); // Write workbook to buffer
        const data = new Blob([excelBuffer], { type: fileType }); // Create a blob from the buffer

        const fileName = `${userName}_InstallationApproval${fileExtension}`; // Define file name

        FileSaver.saveAs(data, fileName); // Save the file
    };

    const handleSelectionChange = (selectionModel) => {
        if (selectionModel && selectionModel.length > 0) {
            // Filter the reports to find the selected rows by matching their Ids with selectionModel
            const selectedIds = reports
                .filter(report => selectionModel.includes(report.Id)) // Find the selected reports
                .map(report => report.Id); // Extract only the Ids

            console.log("Selected row Ids:", selectedIds);  // Logs the selected Ids like [24]
            setSelectedRows(selectedIds);  // Update selectedRows state with the selected Ids
        } else {
            console.log("No rows selected");
            setSelectedRows([]);  // Clear selected rows when nothing is selected
        }
    };

    const handleApprove = () => {
        const billingMonthName = selectBillingMonth.find(month => month.MonthNo === billingMonth)?.Month || '';
        if (selectedRows.length === 0) {
            enqueueSnackbar("Please select row");
            return;
        }
        if (billingMonthName.length === 0) {
            enqueueSnackbar("Please select Billing Month");
            return;
        }
        const payload = {
            Id: 0,
            Month: billingMonthName,
            Ids: selectedRows,
        };
        const formattedMonths = monthYear.map(item => item.Month).join(", ");
        const filterPayload = {
            Month: formattedMonths,
            ClientId: clients,
        };
        console.log("payload",payload)
        URLS.updateOAInstallationReportMasterApprovalStatus(payload)
            .then((response) => {
                setIsLoading(false);
                URLS.filterInstallationApprovalData(filterPayload)
                    .then((response) => {
                        setIsLoading(false);
                        setReports(Array.isArray(response.data) ? response.data : []);
                    })
                    .catch(error => {
                        setIsLoading(false);
                        setReports([]);
                    });
                setBillingMonth('')

                enqueueSnackbar("Approve data and Send for Billing successfully", { variant: 'success' });

            })
            .catch(error => {
                setIsLoading(false);
                enqueueSnackbar("Data not saved", { variant: 'error' });

            });
    }
    // Reset function
    const onResetClick = () => {
        setClients('');
        setReports([]);
        setMonthYear([]);
        setBillingMonth('')
    };

    // Handle export dropdown open/close
    const handleExport = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    useEffect(() => {
        setUserId(CurrentLoggedInUser.ExecutiveId);
        setUserName(CurrentLoggedInUser.ExecutiveName);
    }, []);


    useEffect(() => {
        if (userId) {
            URLS.getClientddl({ Id: userId })
                .then((response) => setSelectClient(response.data))
                .catch(error => console.log("Error", error));
        }
    
        URLS.getMonthddl()
            .then((response) => {
                setSelectMonthYear(response.data);
                setSelectBillingMonth(response.data);

            })
            .catch(error => console.log("Error", error));
    }, [userId]);

    useEffect(() => {
        setClients('');
        setReports([]);
    }, [location.key]);



    return (

        <Page title="Installation Approval" >
            <Typography variant="h5" color="#b71c1c" margin={1} >
                Installation Approval
            </Typography>
            <Card >
                <Stack direction="row" alignItems="center" justifyContent="space-between" m={1} spacing={2}>
                    {/* Clients Dropdown */}
                    <FormControl size="small" sx={{ flex: 1 }}>
                        <InputLabel InputLabelProps={{ style: { color: 'green', fontSize: '14px' } }}>
                            Clients
                        </InputLabel>
                        <Select
                            label="Clients"
                            value={clients}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        backgroundColor: '#fbe9e7',
                                        maxHeight: '200px',
                                        width: '200px',
                                    },
                                },
                            }}
                            onChange={handleClients}
                        >
                            <MenuItem key={0} value={""} >Select</MenuItem>

                            {selectClient.map((client) => (
                                <MenuItem key={client.Id} value={client.Id}>
                                    {client.Name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    {/* Month-Year Dropdown */}
                    <FormControl size="small" sx={{ flex: 1 }}>
                        
                        <Autocomplete
                            multiple
                        fullWidth
                        size="small"
                        id="month-year-select"
                        disableCloseOnSelect
                            value={monthYear}
                            onChange={handleMonthYear}
                            options={selectMonthYear}
                            getOptionLabel={(option) => option.Month} // Display month name
                            isOptionEqualToValue={(option, value) => option.MonthNo === value.MonthNo}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Month-year"
                                   
                                />
                            )}
                            renderOption={(props, option, state) => (
                                <li {...props}>
                                    <Checkbox checked={monthYear.some(item => item.MonthNo === option.MonthNo)} />
                                    <ListItemText primary={option.Month} />
                                </li>
                            )}
                        />
                    </FormControl>

               
                    {/* Action Buttons */}
                    <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={1}
                    >
                       
                        <Tooltip title="Filter">
                            <Button variant="contained" size="small" onClick={onFilterClick}>
                                <Icon icon="ic:outline-filter-alt" width="25px" height="25px" />
                            </Button>
                        </Tooltip>
                        <Tooltip title="Reset">
                            <Button variant="contained" size="small" onClick={onResetClick}>
                                <Icon icon="ic:twotone-restart-alt" width="25px" height="25px" />
                            </Button>
                        </Tooltip>
                        <Tooltip title="Export">
                           
                            <Button
                                variant="contained"
                                size="small"
                                onClick={exportToExcel} // Assuming handleExport is the function for the export action
                                sx={{ marginBottom: 1 }}
                            >
                                <Icon icon="ic:baseline-arrow-downward" width="25px" height="25px" />
                            </Button>
                        </Tooltip>
                        {/* <Menu */}
                        {/*    id="fade-menu" */}
                        {/*    MenuListProps={{ */}
                        {/*        'aria-labelledby': 'fade-button', */}
                        {/*    }} */}
                        {/*    anchorEl={anchorEl} */}
                        {/*    open={open} */}
                        {/*    onClose={handleClose} */}
                        {/*    TransitionComponent={Fade} */}
                        {/* > */}
                        {/*    <MenuItem onClick={handleClose}> */}
                        {/*        <Button sx={{ color: 'black' }} size="small" onClick={exportToExcel}> */}
                        {/*            Export to excel */}
                        {/*        </Button> */}
                        {/*    </MenuItem> */}
                        {/* </Menu> */}
                    </Stack>
                </Stack>

                <Divider />

                {/* ----------------------------------------------------------------- */}

                <div style={{ height: '52vh', width: '100%' }}>
                    <DataGrid
                        rows={reports}
                        density={'compact'}
                        columns={TABLE_HEAD}
                        getRowId={(row) => row.Id} // Use `Id` here instead of `id`
                        components={{
                            Toolbar: GridToolbar,
                        }}
                        getRowHeight={() => 'auto'}
                        checkboxSelection
                        pageSize={25}
                        onSelectionModelChange={handleSelectionChange}
                    />
                </div>
                <Stack direction="row" alignItems="center" justifyContent="space-between" m={2} spacing={2}>
                    {/* Billing Month TextField */}
                    <FormControl size="small" sx={{ flex: 1 }}>
                        <InputLabel InputLabelProps={{ style: { color: 'green', fontSize: '14px' } }}>
                            Billing Month
                        </InputLabel>
                        <Select
                            label="Billing Month"
                            value={billingMonth}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        backgroundColor: '#fbe9e7',
                                        maxHeight: '200px',
                                        width: '200px',
                                    },
                                },
                            }}
                            style={{ width: '38%' }}
                            onChange={handleBillingMonth}
                        >
                            <MenuItem key={0} value={""} >Select</MenuItem>

                            {selectBillingMonth.map((month) => (
                                <MenuItem key={month.MonthNo} value={month.MonthNo}>
                                    {month.Month}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    
                    {/* Approve and Cancel Buttons */}
                    <Stack direction="row" spacing={2}>
                        <Tooltip title="Approve and Send for Billing">

                            <Button variant="contained" size="small" onClick={handleApprove}>
                                
                                <Icon icon="material-symbols:check-circle-outline" width="25px" height="25px" />
                            </Button>
                        </Tooltip>
                        
                       
                    </Stack>
                </Stack>

            </Card>
            {isLoading && (<LoadingProgressBar sx={{ py: 6 }} />)}


        </Page>


    );
}
