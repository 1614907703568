import * as Yup from 'yup';
import * as React from 'react';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
// material
import { startOfMonth, endOfMonth } from 'date-fns';
import { Tooltip, Stack, TextField, FormControl, InputLabel, Select, Grid, Card, IconButton, Typography, MenuItem, Button, Box, CardMedia, Tabs, Tab, Autocomplete, FormHelperText } from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Moment from 'moment';
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';

import URLS from '../../../services/urls.service';
import AppCache from '../../../services/AppCache';
import { handleError } from '../../Common/Utils';

import ProductTabPanel from './ProductTabPanel';
import ModalPopUp from '../../Common/ModalPopUp';

// -----------------------------------------------------------------------------------------------------


export default function POMasterDetails({ poMaster }) {
    console.log("poMaster", poMaster)
    const navigate = useNavigate();

    const [openModalForCancel, setOpenModalCancel] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    // DropDown Executives responsible
    const CurrentLoggedInUser = AppCache.GetCurrentLoggedInUser();
    const [userId, setUserId] = useState([]);
   
    const [selectedExecutives, setSelectedExecutives] = useState([]);

    const [selectedPODetails, setSelectedPODetails] = useState([]);

    const [selectedMultiplePODataID, setSelectedMultiplePODataID] = useState([]);

    const [poGropsData, setPOGroupsData] = useState([]);

    const [executivesId, setExecutivesId] = useState(poMaster[0]?.ClientId || '');

    const [hide, setHide] = useState(false);

    const [OANumber, setOANumber] = useState('');

    const [OAdisable, setOAdisable] = useState(true);

    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const today = new Date();
    const formattedStartOfMonth = `01/${months[today.getMonth()]}/${today.getFullYear()}`;
    const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    const formattedEndOfMonth = `${lastDayOfMonth.getDate()}/${months[lastDayOfMonth.getMonth()]}/${lastDayOfMonth.getFullYear()}`;


    const [effectiveFromValue, setEffectiveFromValue] = useState(poMaster[0]?.FromDate ? poMaster[0]?.FromDate : formattedStartOfMonth);


    const [effectiveToValue, setEffectiveToValue] = useState(poMaster[0]?.ToDate ? poMaster[0]?.ToDate : formattedEndOfMonth);


    const [valuesSelected, setValuesSelected] = useState(false);
    const [fieldSets, setFieldSets] = useState([
        {
            OADetailsId: 0,
            ProductId: "",
            POItemRemark: "",
            Rate: "",
            Quantity: "",
            Name: "",
            PODetailsId: "",

        },
    ]);
    const message = {
        forCancel: 'Do you want to cancel ? ',
    };
    const forCancelConfirm = (value) => {
        if (value) {
            navigate('/dashboard/oa/pomaster', { replace: true });
            setOpenModalCancel(false);
        }
        else {
            setOpenModalCancel(false);
        }
    };

    const handelCancelClick = () => {
        setOpenModalCancel(true);

    };

  const handleOAChange = (index, field, value) => {
    setFieldSets((prevFieldSets) => {
        const updatedFieldSets = [...prevFieldSets];
        updatedFieldSets[index] = {
            ...updatedFieldSets[index],
            [field]: value,
        };

        // Ensure Quantity value is updated in Formik's form state
        formik.setFieldValue(`PODetailsModel[${index}].${field}`, value);
        return updatedFieldSets;
    });
};

    const handleMultipleData = (selectedItems) => {
        const newDeselectedItems = selectedMultiplePODataID.filter(
            item => !selectedItems.some(selected => selected.OADetailsId === item.OADetailsId)
        );

        setDeselectedItems(prev => [...prev, ...newDeselectedItems]);
        setSelectedMultiplePODataID(selectedItems);
        setValuesSelected(true);

        const updatedFieldSets = selectedItems.map(item => {
            let poDetails = {};
            if (Array.isArray(poMaster)) {
                poDetails = poMaster.find(po => po.OADetailsId === item.OADetailsId && po.ProductId === item.ProductId) || {};
            }
            return {
                OADetailsId: item.OADetailsId,
                ProductId: item.ProductId,
                ProductName: item.ProductName,
                POItemRemark: poDetails.POItemRemark || item.POItemRemark,
                Rate: poDetails.Rate || item.Rate,
                Quantity: poDetails.Quantity || item.Quantity,
                OANumber: item.OANumber,
                PODetailsId: item.PODetailsId || 0,
                OAMasterId: item.OAMasterId
            };
        });

        setFieldSets(updatedFieldSets);
        updatedFieldSets.forEach((item, index) => {
            formik.setFieldValue(`PODetailsModel[${index}].Quantity`, item.Quantity);
        });
    };

    const handleExecutives = (id) => {
        // Convert id to string or set it to empty string if undefined or empty
        const executiveId = id !== undefined && id !== null ? String(id) : '';
        formik.setFieldValue('ClientId', executiveId);
        setExecutivesId(executiveId);
        // Clear selected data if ExecutiveId is not selected
        if (!id) {
            setSelectedMultiplePODataID([]);
        }
    };

    const [itemToDelete, setItemToDelete] = useState(null);

    const handleDelete = (index) => {
        // Create a copy of the fieldSets array
        const updatedFieldSets = [...fieldSets];
        // Remove the accordion at the specified index
        updatedFieldSets.splice(index, 1);
        // Update the fieldSets state with the modified array
        setFieldSets(updatedFieldSets);
    };
    const handleDeselectionConfirm = (confirmed) => {
        if (confirmed && itemToDelete !== null) {
            const updatedItems = selectedMultiplePODataID.filter(item => item.OADetailsId !== itemToDelete.OADetailsId);
            handleMultipleData(updatedItems);
        }
        setItemToDelete(null);
    };

    const handleEffFrom = (newValue) => {
        const selectedDate = new Date(newValue);
        const formattedDate = `${selectedDate.getDate()} ${months[selectedDate.getMonth()]} ${selectedDate.getFullYear()}`;
        setEffectiveFromValue(formattedDate);
      

    };
    const handleEffTo = (newValue) => {
        const selectedDate = new Date(newValue);
        const formattedDate = `${selectedDate.getDate()} ${months[selectedDate.getMonth()]} ${selectedDate.getFullYear()}`;
        setEffectiveToValue(formattedDate);
     
    };
    useEffect(() => {
        setUserId(CurrentLoggedInUser.ExecutiveId);
    }, []);

    useEffect(() => {
        URLS.getOAClient()
            .then((response) => {
                setSelectedExecutives(response.data);
            })
            .catch(error => {
                // On error
                console.log(" Error ");
            })
        const payload = { ClientID: executivesId };
      
    }, []);


    useEffect(() => {
        if (executivesId) {
            const payload = { ClientID: executivesId };
            URLS.getOAProductWiseDataForPO(payload)
                .then((response) => {
                    setOAdisable(false);
                    setSelectedPODetails(response.data);
                   
                })
                .catch(error => {
                    // Handle error
                    console.error("Error fetching product data:", error);
                });
        }
    }, [executivesId]);

    useEffect(() => {
        setUserId(CurrentLoggedInUser.ExecutiveId);
        if (poMaster && poMaster.length > 0) {
            const newFieldSets = poMaster.map(data => ({
                ...data
            }));
            setFieldSets(newFieldSets);
        }
    }, []);

    const poValidateSchema = Yup.object().shape({
        PONumber: Yup.string().required('PO Number is required'),
        selectedMultiplePODataID: Yup.array().min(1, 'OA Details are required'), // Add validation for minimum selection
      PODetailsModel: Yup.array().of(
        Yup.object().shape({
            Quantity: Yup.number().required('Quantity is required').min(1, 'Quantity must be positive'),
        })
        ),
        ClientId: Yup.string().required('Please select a client'),
    });

    const poDetailsIds = Array.isArray(poMaster) ? poMaster.map(item => item.PODetailsId) : [];
    const [deselectedItems, setDeselectedItems] = useState([]);

    const formik = useFormik({
        initialValues: {
            POMasterId: (poMaster && poMaster.length > 0) ? poMaster[0].POMasterId : 0,
            FromDate: effectiveFromValue || "",
            ToDate: effectiveToValue || "",
            ExecutiveId: userId,
            ClientId: executivesId,
            PONumber: poMaster[0]?.PONumber,
            Remark: (poMaster && poMaster.length > 0) ? poMaster[0].Remark : '',
            PODetailsModel: fieldSets.map(item => ({
                OADetailsId: item.OADetailsId,
                ProductId: item.ProductId,
                POItemRemark: item.POItemRemark || poMaster[0]?.POItemRemark,
                Rate: item.Rate,
                Quantity: item.Quantity || poMaster[0]?.Quantity,
                Name: item.ProductName,
                PODetailsId: item.PODetailsId,
                OANumber: item.OANumber,
            })),
        },
        validationSchema: poValidateSchema,
        onSubmit: (values, actions) => {
            const payload = {
                POMasterId: poMaster.length > 0 ? poMaster[0].POMasterId : 0,
                PONumber: values.PONumber,
                FromDate: effectiveFromValue,
                ToDate: effectiveToValue,
                ClientId: executivesId,
                Remark: values.Remark || "",
                CreatedBy: userId,
                EditedBy: userId,
                PODetailsSaveModel: [],
            };
         

            if (payload.POMasterId === 0) {

               selectedMultiplePODataID.forEach(item => {
                payload.PODetailsSaveModel.push({
                    PODetailsId: 0,
                    OADetailsId: item.OADetailsId,
                    POMasterId: 0,
                    ProductId: item.ProductId,
                    PODetailsRemark: item.POItemRemark || "",
                    Rate: item.Rate,
                    Quantity: item.Quantity,
                    CreatedBy: userId,
                    isDelete: false,
                    DeletedBy: null,
                    EditedBy: userId
                });
            });
            } else {
                payload.PODetailsSaveModel = [
                    ...fieldSets.map((item, index) => {
                        const poDetailsId = typeof poDetailsIds[index] !== 'undefined' ? poDetailsIds[index] : 0;
                        return {
                            PODetailsId: poDetailsId,
                            OADetailsId: item.OADetailsId,
                            POMasterId: poMaster.length > 0 && typeof poMaster[0].POMasterId !== 'undefined' ? poMaster[0].POMasterId : 0,
                            ProductId: item.ProductId,
                            PODetailsRemark: item.POItemRemark || "",
                            Rate: item.Rate,
                            Quantity: item.Quantity,
                            CreatedBy: userId,
                            isDelete: false,
                            DeletedBy: null,
                            EditedBy: userId
                        };
                    }).filter(item => !(item.PODetailsId === 0 && item.isDelete)),

                    ...deselectedItems.map(item => {
                        const poDetailsId = (Array.isArray(poMaster) ? poMaster.find(po => po.OADetailsId === item.OADetailsId && po.ProductId === item.ProductId)?.PODetailsId : null) || item.PODetailsId || 0;
                        return {
                            PODetailsId: poDetailsId,
                            OADetailsId: item.OADetailsId,
                            POMasterId: poMaster.length > 0 && typeof poMaster[0].POMasterId !== 'undefined' ? poMaster[0].POMasterId : 0,
                            ProductId: item.ProductId,
                            PODetailsRemark: item.POItemRemark || "",
                            Rate: item.Rate,
                            Quantity: item.Quantity,
                            CreatedBy: userId,
                            isDelete: true,
                            DeletedBy: userId,
                            EditedBy: userId
                        };
                    }).filter(item => !(item.PODetailsId === 0 && item.isDelete))
                ];
            }

            console.log("payload>>>>>>", payload, selectedMultiplePODataID)

            const checkPayload = { ClientID: '', POdate: effectiveFromValue, ToDate: effectiveToValue }

            URLS.getPOMasterListing(checkPayload)
                .then((response) => {
                    const matchingPONumbers = response.data.filter(item => item.PONumber === values.PONumber);
                    if (payload.POMasterId === 0 && matchingPONumbers.length > 0) {
                        enqueueSnackbar("PO number already exist. Please select a different PO number.", { variant: 'error' });
                    } else {
                        URLS.savePOMasterListing(payload)
                            .then((response) => {
                                if (response.status === 200) {
                                    navigate('/dashboard/oa/pomaster', { replace: true });
                                }
                                enqueueSnackbar("Data Saved successfully", { variant: 'success' });
                            })
                            .catch(error => {
                                enqueueSnackbar("Please select a OADetails", { variant: 'error' });
                            });
                    }
                })
                .catch(error => {
                    enqueueSnackbar("Data not found", { variant: 'error' });
                });
            setOpenModalCancel(false);
        },
    });

    useEffect(() => {
        if (poMaster.length > 0 && selectedPODetails.length > 0) {
            // Initialize an array to store all matching details
            const allMatchingDetails = [];

            // Loop through each item in poMaster
            poMaster.forEach(masterDetail => {
                // Loop through each item in selectedPODetails to find matching details
                selectedPODetails.forEach(selectedDetail => {
                    if (
                        selectedDetail.OADetailsId === masterDetail.OADetailsId &&
                        selectedDetail.ProductId === masterDetail.ProductId
                    ) {
                        // Add the matching detail to allMatchingDetails
                        allMatchingDetails.push(selectedDetail);
                    }
                });
            });

            // Update selectedMultiplePODataID and fieldSets with all matching details
            setSelectedMultiplePODataID(allMatchingDetails);

            const updatedFieldSets = allMatchingDetails.map(detail => ({
                OADetailsId: detail.OADetailsId,
                ProductId: detail.ProductId,
                POItemRemark: detail.POItemRemark,
                Rate: detail.Rate,
                Quantity: detail.Quantity,
                Name: detail.ProductName,
                PODetailsId: detail.PODetailsId,
                OAMasterId: detail.OAMasterId
            }));

            setFieldSets(updatedFieldSets);
        }
    }, [poMaster, selectedPODetails]);



  
    const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;
    useEffect(() => {
        // Call handleMultipleData with the preselected value when the component mounts
        handleMultipleData(selectedMultiplePODataID);
    }, []);

    useEffect(() => {
        // Call handleMultipleData when a new preselected value is set
        handleMultipleData(selectedMultiplePODataID);
    }, [selectedMultiplePODataID]);



    return (
        <FormikProvider value={formik}>
            <Form variant="body2" autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={2}>
                <Stack direction={{ xs: "column", sm: "row" }} spacing={4}>
                  
                        <TextField
                            size="small"
                            label="PO Number"
                            fullWidth
                            {...getFieldProps('PONumber')}
                            error={Boolean(touched.PONumber && errors.PONumber)}
                            helperText={touched.PONumber && errors.PONumber}
                        />
                       
                        <FormControl size="small" fullWidth error={Boolean(touched.ClientId && errors.ClientId)} >
                            <InputLabel InputLabelProps={{ style: { color: 'green', fontSize: '14px' } }}>Clients</InputLabel>
                            <Select
                                label="Clients"
                                size="small"
                                value={formik.values.ClientId} 
                                onChange={(event) => handleExecutives(event.target.value)} 
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            backgroundColor: '#fbe9e7',
                                            maxHeight: '200px',
                                            width: '200px',
                                        }
                                    }
                                }}
                                onBlur={formik.handleBlur}
                            >
                                <MenuItem key={0} value={""} onClick={() => handleExecutives()}>Select</MenuItem>
                                {selectedExecutives.map((product) => (
                                    <MenuItem key={product.ContactId} value={product.ContactId} onClick={() => handleExecutives(product.ContactId)}>{product.Name}</MenuItem>
                                ))}
                            </Select>
                            <FormHelperText>{touched.ClientId && errors.ClientId}</FormHelperText>

                        </FormControl>
                    </Stack>
                       
                    <Stack direction="row" spacing={ 2} >
                      
                        <Autocomplete
                            multiple
                            fullWidth
                            size="small"
                            id="checkboxes-tags-demo"
                            disableCloseOnSelect
                            getOptionLabel={(option) => `${option.OANumber}-${option.ProductName}-${option.Quantity}`}
                            onChange={(event, value) => {
                                const deselectedItems = selectedMultiplePODataID.filter(item => !value.some(v => v.OADetailsId === item.OADetailsId));
                                if (deselectedItems.length > 0) {
                                    setItemToDelete(deselectedItems[0]);
                                } else {
                                    handleMultipleData(value);
                                    formik.setFieldValue('selectedMultiplePODataID', value);
                                }
                            }}
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                    <Checkbox checked={selected} />
                                    {`${option.OANumber}-${option.ProductName}-${option.Quantity}`}
                                </li>
                            )}
                            style={{ width: 580 }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="OADetails"
                                    error={Boolean(touched.selectedMultiplePODataID && errors.selectedMultiplePODataID)}
                                    helperText={touched.selectedMultiplePODataID && errors.selectedMultiplePODataID}
                                />
                            )}
                            getOptionSelected={(option, value) => option.Id === value.Id}
                            options={selectedPODetails}
                            disabled={!executivesId}
                            value={selectedMultiplePODataID}
                        />
                        {itemToDelete && (
                            <ModalPopUp
                                openCloseValue={setItemToDelete}
                                msg={`Do you want to delete ${itemToDelete.OANumber}-${itemToDelete.ProductName}-${itemToDelete.Quantity}?`}
                                confirm={handleDeselectionConfirm}
                            />
                        )}


                       
                    <TextField
                        size="small"
                        label="Remark"
                        style={{width:'49.50%'} }
                        fullWidth
                        {...getFieldProps('Remark')}
                        error={Boolean(touched.Remark && errors.Remark)}
                        helperText={touched.Remark && errors.Remark}
                            multiline
                            maxRows={4}
                        />
                    </Stack>
                    <Stack direction="row" spacing={2}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                                label="From Date"
                                inputFormat="dd/MMM/yyyy"
                                value={Moment(effectiveFromValue).format('DD-MMM-yyyy')}
                                onChange={handleEffFrom}
                                renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                            />
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                                label="To Date"
                                inputFormat="dd/MMM/yyyy"
                                value={Moment(effectiveToValue).format('DD-MMM-yyyy')}
                                onChange={handleEffTo}
                                renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                            />
                        </LocalizationProvider>
                    </Stack>
                    {/* Container for from poGropsData */}

                
            </Stack>
                <div style={{ padding: '16px', margin: '16px 0' }}>
                    {valuesSelected && fieldSets.map((item, index) => (
                        <LocalizationProvider key={index} dateAdapter={AdapterDateFns}>
                            <Card sx={{ padding: '10px', margin: 2 }}>
                                <Grid container>
                                    <Grid item xs={11.2} md={11.2} lg={11.2}>
                                        <Stack spacing={2}>
                                            <Stack direction="row" spacing={3}>
                                                <TextField
                                                    size="small"
                                                    label="OA Number"
                                                    onChange={(e) => handleOAChange( index, 'OANumber',e.target.value)}
                                                    value={item.OANumber }
                                                    fullWidth
                                                    disabled
                                                />
                                                <TextField size="small" label="Product Name" value={item.ProductName || ""}
                                                    disabled fullWidth />
                                                <TextField
                                                    size="small"
                                                    label="Rate"
                                                    type="number"
                                                    onChange={(e) => handleOAChange(index, 'Rate',e.target.value)}
                                                    value={item.Rate || ''}
                                                    fullWidth
                                                />
                                            </Stack>
                                            <Stack direction="row" spacing={3}>
                                                <TextField
                                                    size="small"
                                                    label="Quantity"
                                                    type="number"
                                                    value={item.Quantity}
                                                    onChange={(e) => handleOAChange(index, 'Quantity', e.target.value)}
                                                    onBlur={() => {
                                                        // Update touched state for the Quantity field
                                                        formik.setFieldTouched(`PODetailsModel[${index}].Quantity`, true);
                                                    }}
                                                    fullWidth
                                                    error={Boolean(touched.PODetailsModel && touched.PODetailsModel[index]?.Quantity && errors.PODetailsModel && errors.PODetailsModel[index]?.Quantity)}
                                                    helperText={touched.PODetailsModel && touched.PODetailsModel[index]?.Quantity && errors.PODetailsModel && errors.PODetailsModel[index]?.Quantity}
                                                />

                                                <TextField size="small" label="Remark" value={item.POItemRemark} onChange={(e) => handleOAChange(index, 'POItemRemark', e.target.value)} onBlur={() => {
                                                    // Update touched state for the Quantity field
                                                    formik.setFieldTouched(`PODetailsModel[${index}].POItemRemark`, true);
                                                }} fullWidth />
                                            </Stack>
                                        </Stack>
                                    </Grid>
                                   
                                </Grid>
                            </Card>
                        </LocalizationProvider>
                    ))}
                <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="center" spacing={2} margin={ 2}>

                    <LoadingButton
                        size="medium"
                        type="submit"
                        variant="contained"
                        sx={{ color: 'white' }}
                    >
                        Save
                    </LoadingButton>

                    <Button
                        sx={{ color: 'white' }}
                        onClick={handelCancelClick}
                        size="medium"
                        variant="contained"
                        color="primary"
                    >
                        Cancel
                    </Button>
                </Stack>
        </div >


            </Form>
            {openModalForCancel && <ModalPopUp openCloseValue={setOpenModalCancel} msg={message.forCancel} confirm={forCancelConfirm} />}

        </FormikProvider>

    );
}