import * as Yup from 'yup';
import * as React from 'react';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
// material
import {
    Tooltip, Stack, TextField, Typography, Modal, FormControl, InputLabel, Select, MenuItem, Button,
    IconButton, Grid, Radio, RadioGroup, FormControlLabel, FormLabel, Divider, Dialog, DialogTitle,
    DialogContent, DialogContentText, DialogActions
} from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import TreeView from '@mui/lab/TreeView';
import TreeItem from '@mui/lab/TreeItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { startOfMonth, endOfMonth } from 'date-fns';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Moment from 'moment';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import ModalPopUp from '../../Common/ModalPopUp';
import URLS from '../../../services/urls.service';
import AppCache from '../../../services/AppCache';
import Iconify from '../../Iconify';

// -----------------------------------------------------------------------------------------------------

const style = {
    position: 'absolute',
    top: '50%',
    justifyContent: 'center',
    left: '50%',
    transform: 'translate(-50%, -50%)',

    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 10,
    p: 4,
};


export default function IRQuestionnairesDetailsComponent() {

    const TABLE_HEAD = [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'description', headerName: 'Description', width: 150 },
        { field: 'controlId', headerName: 'Control ID', width: 150 },
        {
            field: 'parentName', headerName: 'Parent ID', width: 150,
            
        },
        {
            field: 'actions',
            headerName: 'Actions',
            type: 'actions',
            width: 130, // Adjust the width according to your needs
            renderCell: (cellValues) => {
                console.log("cell", cellValues);
                return (
                    <div>
                        <IconButton
                            onClick={(event) => {
                                handleClick(event, cellValues); // pass cellValues.row.id as the second argument
                            }}
                        >
                            <Iconify icon="ic:outline-edit" width='20px' height='20px' color='primary.main' />
                        </IconButton>
                        <IconButton
                            onClick={(event) => {
                                handleDelete(event, cellValues); // pass cellValues.row.id as the second argument
                            }}
                        >
                            <Iconify icon="ic:baseline-delete" width='20px' height='20px' color='primary.main' />
                        </IconButton>
                        <IconButton>
                            <Iconify icon="ic:outline-more-vert" width='20px' height='20px' color='primary.main' />
                        </IconButton>
                    </div>
                );
            }
        }
    ];

    const namedControls = [
        { id: 'TextBox', name: 'TextBox' },
        { id: 'Label', name: 'Label' },
        { id: 'DropDownlist', name: 'DropDownlist' },
        { id: 'RadioButton', name: 'RadioButton' },
        { id: 'Checkbox', name: 'Checkbox' }
    ];

    // Adding
    const [description, setDescription] = useState("");
    const [controlId, setControlId] = useState("");
    const [dataRows, setDataRows] = useState([]);
    const [selectedRowId, setSelectedRowId] = useState(null);
    const [showFirstDescription, setShowFirstDescription] = useState(false);
    const [clickedOnce, setClickedOnce] = useState(false);
    const [textValue, setTextValue] = useState(false);

    const [idData, setIdData] = useState('');
    const [name, setName] = useState('');
    const [tableName, setTableName] = useState('');
    const [where, setWhere] = useState('');
    const [defaultValue, setDefaultValue] = useState('');
    const [dataValue, setDataValue] = useState('');

    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    const [itemToDelete, setItemToDelete] = useState(null);
    const [disable, setDisable] = useState(true);
 const [transformedData, setTransformedData] = useState([]);
    const [products, setProducts] = useState([]);
    const [selectedProductsId, setSelectedProductsId] = useState('');
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [userId, setUserId] = useState([]);
    const [effectiveFromValue, setEffectiveFromValue] = useState(startOfMonth(new Date()));
    const [effectiveToValue, setEffectiveToValue] = useState(endOfMonth(new Date()));
    const [isCheckedActive, setIsCheckedAcive] = useState(true);
    const [openModalForCancel, setOpenModalCancel] = useState(false);
    const CurrentLoggedInUser = AppCache.GetCurrentLoggedInUser();
    const [parentId, setParentId] = useState([]);
    const [selectParentId, setSelectParentId] = useState('');
    const [selectedParentName, setSelectedParentName] = useState('');
    const [selectedParent, setSelectedParent] = useState(null);  // Initially, set it to null or empty

    const controlMapping = {
        "TextBox": 0,
        "DropDownlist": 1,
        "ListBox": 2,
        "CheckBox": 3,
        "CheckBoxList": 4,
        "RadioButton": 5,
        "label": 6
    };

    // Effect to transform dataRows whenever it changes
    useEffect(() => {
        const transformed = dataRows.map(row => {
            const controlId = controlMapping[row.controlId] || -1;
            const { idData, name, where } = row;
            let defaultValue = "";
            const valueList = [];

            if (controlId === 1) {
                valueList.push(name);
            } else if (controlId === 3) {
                defaultValue = name;
                valueList.push(name);
            }

            return {
                ID: idData,
                NAME: name,
                WhereClause: where,
                Control: controlId,
                DefaultValue: defaultValue,
                ValueList: valueList
            };
        });
        setTransformedData(transformed);
    }, [dataRows]);

    useEffect(() => {
        URLS.getOAProductsDropDown()
            .then((response) => {

                setProducts(response.data);
            })
            .catch(error => {
                // On error
                console.log(" Error ");
            });
        URLS.getParentQuestionaries()
            .then((response) => {

                setParentId(response.data);
            })
            .catch(error => {
                // On error
                console.log(" Error ");
            });
    }, []);
    // Function to handle save
    useEffect(() => {
        setUserId(CurrentLoggedInUser.ExecutiveId);
        setClickedOnce(true);
    }, [clickedOnce]);

    const handleEffFrom = (newValue) => {
        setEffectiveFromValue(newValue)
    };
    const handleEffTo = (newValue) => {
        setEffectiveToValue(newValue)
    };

    const handleIsActiveChange = (event) => {
        setIsCheckedAcive(event.target.checked);
    };

    const message = {
        forCancel: 'Do you want to cancel ? ',
    };
    const forCancelConfirm = (value) => {
        if (value) {
            navigate('/dashboard/oa/productlinking', { replace: true });
            setOpenModalCancel(false);
        }
        else {
            setOpenModalCancel(false);
        }
    };

    const handelCancelClick = () => {
        setOpenModalCancel(true);

    };
    const handleProducts = (id) => {
        setSelectedProductsId(id);
        setDisable(false)
    };

        const handleParentId = (id) => {
            setSelectParentId(id);
      
        };

    const handleCancelDelete = () => {
        console.log("Cancel delete clicked"); 
        setOpenDeleteModal(false); // Close the modal without deleting
    }

    const handleChangeControl = (id) => {
        setControlId(id);

    };
    const handleClick = (event, cellValues) => {
        setSelectedRowId(cellValues.row.id);
        setDescription(cellValues.row.description);
        setControlId(cellValues.row.controlId);
        console.log("cell", cellValues);
    }
   
    const handleAddData = () => {
        if (!selectedProductsId) {
            enqueueSnackbar("Please select product");
            return;
        }

        let newParentId = '';
        let newParentName = '';

        // Logic to determine the parent ID and name
        if (dataRows.length === 0 && (controlId === 'DropDownList' || controlId === 'RadioButton' || controlId === 'Checkbox')) {
            newParentId = selectedParent?.Id || '';
            newParentName = selectedParent?.QuestionDescription || '';
        } else if (dataRows.length > 0) {
            newParentId = dataRows[0].parentId; // Use existing row's parentId
            newParentName = dataRows[0].parentName; // Use existing row's parentName
        }

        // Find the parent name using the selected parent ID
        const parent = parentId.find((p) => p.Id === selectParentId);
        newParentName = parent ? parent.QuestionDescription : ''; // Adjust this line if necessary

        const newDataRows = [
            ...dataRows,
            {
                id: dataRows.length + 1,
                description,
                controlId,
                parentId: selectParentId,
                parentName: newParentName,  // Store the parent name
                idData: idData || '',
                name: name || '',
                tableName: tableName || '',
                where: where || ''
            }
        ];

        // Log final dataRows before setting state
        console.log('New Data Rows:', newDataRows);

        // Reset and update the state
        setDataRows(newDataRows);
        // Clear the form fields as needed...
    };
    const handleConfirmDelete = () => {
        // Remove the selected item from dataRows
        const updatedRows = dataRows.filter(row => row.id !== itemToDelete.id);
        setDataRows(updatedRows); // Update the state with the new rows

        // Close the delete modal
        setOpenDeleteModal(false);
        // Optionally show a notification or message indicating success
        enqueueSnackbar("Item deleted successfully");
    };
    const handleDelete = (event, cellValues) => {
        console.log("Item to delete:", cellValues.row);
        setItemToDelete(cellValues.row); // Set the row to be deleted
        setOpenDeleteModal(true); // Open the delete confirmation modal
    };
    // Function to confirm deletion
    const confirmDelete = () => {
        if (itemToDelete) {
            // Delete the item
            const updatedDataRows = dataRows.filter(row => row.id !== itemToDelete.id);

            // Renumber the IDs of the remaining items
            const renumberedDataRows = updatedDataRows.map((row, index) => ({
                ...row,
                id: index + 1 // Set the ID to be sequential starting from 1
            }));

            setDataRows(renumberedDataRows); // Update the state with renumbered rows
        }
        setOpenDeleteModal(false); // Close the modal
        setItemToDelete(null); // Clear the item to delete
    };

    console.log("dataRows", dataRows)
    const handleSave = () => {
        // Convert transformedData into the desired format
        const savedData = transformedData.map(item => ({
            [item.ID]: {
                ID: item.ID,
                NAME: item.NAME,
                WhereClause: item.WhereClause,
                Control: item.Control,
                DefaultValue: item.DefaultValue,
                ValueList: item.ValueList
            }
        }));

        // Here you can implement the logic to save savedData
        console.log("Saving data:", savedData);
        // Example: send savedData to server, or save it to local storage, etc.
    };

    const handleUpdateData = () => {
        if (selectedRowId !== null) {
            const updatedRows = dataRows.map(row => {
                if (row.id === selectedRowId) {
                    const updatedControlId = controlId;
                    let showControlValuePanel = true;

                    // Check if the control type is changed to TextBox or Label
                    if ((row.controlId === 'TextBox' || row.controlId === 'Label') && (controlId === 'DropDownList' || controlId === 'RadioButton' || controlId === 'Checkbox')) {
                        showControlValuePanel = false;
                    } else if ((row.controlId === 'DropDownList' || row.controlId === 'RadioButton' || row.controlId === 'Checkbox') && (controlId === 'TextBox' || controlId === 'Label')) {
                        showControlValuePanel = false;
                    }

                    return { ...row, description, controlId: updatedControlId, showControlValuePanel };
                }
                return row;
            });
            setDataRows(updatedRows);
            setSelectedRowId(null); // Reset selected row after update
            setDescription('');
            setControlId('');
        }
    };
    const formik = useFormik({
        initialValues: {

            ProductId: selectedProductsId,
            FromDate: effectiveFromValue,
            ToDate: effectiveToValue,
            IsActive: isCheckedActive,
        },

        onSubmit: (values, actions) => {
            if (!selectedProductsId) {
                enqueueSnackbar("Please select product");
                return;
            }

            const payload = {
                "Id": null,
                "ProductId": selectedProductsId,
                "FromDate": effectiveFromValue,
                "ToDate": effectiveToValue,
                "IsActive": isCheckedActive,
                "LoggedInUserId": userId,
                saveQuestionariesList: dataRows.map((row) => ({
                    Id: row.id || 0, // Use row.id or default to 0 if undefined
                    Description: row.description || "", // Use row.description or default to an empty string
                    ControlType: row.controlId || "", // Use row.controlId or default to an empty string
                    ParentId: row.parentId ? parseInt(row.parentId, 10) : 0, // Parse row.parentId as an integer or default to 0
                    DataValueId: row.idData || 0, // Use row.id or default to 0 if undefined
                    Name: row.name || "", // Use row.name or default to an empty string
                    TableName: row.tableName || "", // Use row.tableName or default to an empty string
                    Where: row.where || "" // Use row.where or default to an empty string
                }))
            };
           
             URLS.saveQuestionaries(payload)
                .then((response) => {
                    
                })
                .catch(error => {
                });
            console.log("payload", payload);
        },
    });

    const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;



    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit} >
                <Stack spacing={2}>
                    <Stack direction={{ xs: "column", sm: "row" }} spacing={4}>
                        <FormControl size="small" fullWidth>
                            <InputLabel InputLabelProps={{ style: { color: 'green', fontSize: '14px' } }}>Products</InputLabel>
                            <Select
                                label="Prducts"
                                size="small"
                                value={selectedProductsId}
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            backgroundColor: '#fbe9e7', // Set the background color here
                                            maxHeight: '200px',
                                            width: '200px',// Set the max height here
                                        }
                                    }
                                }}
                            >
                                <MenuItem key={0} value={""}>Select</MenuItem>
                                {products.map((product) => (
                                    <MenuItem key={product.Id} value={product.Id} onClick={() => handleProducts(product.Id)}>{product.Name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                  
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                                fullWidth
                                label="From Date"
                                inputFormat="dd/MMM/yyyy"
                                value={Moment(effectiveFromValue).format('DD-MMM-yyyy')}
                                onChange={handleEffFrom}
                                renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                            />
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                                fullWidth
                                label="To Date"
                                inputFormat="dd/MMM/yyyy"
                                value={Moment(effectiveToValue).format('DD-MMM-yyyy')}
                                onChange={handleEffTo}
                                renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                            />
                        </LocalizationProvider>
                      
                        <FormGroup>
                            <FormControlLabel control={<Checkbox checked={isCheckedActive} onChange={handleIsActiveChange} />} label="IsActive" style={{ width: '150px' }} />
                        </FormGroup>
                    </Stack>
                    <Divider mb={2 } />
                    <Stack direction={{ xs: "column", sm: "row" }} spacing={4}>
                        <Grid container spacing={2} direction={{ xs: "column", sm: "row" }}>
                            {/* Description TextField */}
                            <Grid item xs={12} sm={4} md={3}>
                                <TextField
                                    fullWidth
                                    label="Description"
                                    size="small"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    disabled={disable}
                                />
                            </Grid>

                            {/* Control Type Dropdown */}
                            <Grid item xs={12} sm={4} md={3}>
                                <FormControl fullWidth size="small">
                                    <InputLabel InputLabelProps={{ style: { color: 'green', fontSize: '14px' } }}>Control Type</InputLabel>
                                    <Select
                                        label="Control Type"
                                        size="small"
                                        value={controlId}
                                        disabled={disable}
                                        MenuProps={{
                                            PaperProps: {
                                                sx: {
                                                    backgroundColor: '#fbe9e7',
                                                    maxHeight: '200px',
                                                    width: '200px',
                                                },
                                            },
                                        }}
                                        onChange={(e) => setControlId(e.target.value)}
                                    >
                                        <MenuItem key={0} value={""}>Select</MenuItem>
                                        {namedControls.map((product) => (
                                            <MenuItem key={product.id} value={product.id} onClick={() => handleChangeControl(product.id)}>
                                                {product.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            {/* Parent Id Dropdown */}
                            <Grid item xs={12} sm={4} md={3}>
                                <FormControl size="small" fullWidth>
                                    <InputLabel InputLabelProps={{ style: { color: 'green', fontSize: '14px' } }}>Parent Id</InputLabel>
                                    <Select
                                        label="Parent Id"
                                        size="small"
                                        value={selectParentId}
                                        disabled={disable}
                                        MenuProps={{
                                            PaperProps: {
                                                sx: {
                                                    backgroundColor: '#fbe9e7',
                                                    maxHeight: '200px',
                                                    width: '200px',
                                                },
                                            },
                                        }}
                                    >
                                        <MenuItem key={0} value={""}>Select</MenuItem>
                                        {parentId.map((product) => (
                                            <MenuItem key={product.Id} value={product.Id} onClick={() => handleParentId(product.Id)}>
                                                {product.QuestionDescription}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            {/* Control Type Description */}
                            {showFirstDescription && (
                                <Grid item xs={12} sm={4} md={3}>
                                    <Typography>
                                        <span style={{ fontWeight: 'bold' }}>Control Type:</span> {dataRows[0].description}
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>
                    </Stack>

                    {(controlId === "DropDownlist" || controlId === "RadioButton" || controlId === "CheckBox") && (
                            <>
                            <Stack direction={{ xs: "column", sm: "row" }} spacing={4}>

                                <TextField
                                    fullWidth
                                    label="Id"
                                    size="small"
                                    value={idData} // Assuming 'id' is a state variable
                                    onChange={(e) => setIdData(e.target.value)} // Assuming you have a corresponding state update function setId
                                />

                                <TextField
                                    fullWidth
                                    label="Name"
                                    size="small"
                                    value={name} // Assuming 'name' is a state variable
                                    onChange={(e) => setName(e.target.value)} // Assuming you have a corresponding state update function setName
                                />

                                <TextField
                                    fullWidth
                                    label="Table Name"
                                    size="small"
                                    value={tableName} // Assuming 'tableName' is a state variable
                                    onChange={(e) => setTableName(e.target.value)} // Assuming you have a corresponding state update function setTableName
                                />
                            </Stack>

                            <Stack direction={{ xs: "column", sm: "row" }} spacing={4}>

                                <TextField
                                    fullWidth
                                    label="Where"
                                    size="small"
                                    value={where} // Assuming 'where' is a state variable
                                    onChange={(e) => setWhere(e.target.value)} // Assuming you have a corresponding state update function setWhere
                                />

                                <TextField
                                    fullWidth
                                    label="Default Values"
                                    size="small"
                                    value={defaultValue} // Assuming 'where' is a state variable
                                    onChange={(e) => setDefaultValue(e.target.value)} // Assuming you have a corresponding state update function setWhere
                                />

                                <TextField
                                    fullWidth
                                    label="Data Values"
                                    size="small"
                                    value={dataValue} // Assuming 'where' is a state variable
                                    onChange={(e) => setDataValue(e.target.value)} // Assuming you have a corresponding state update function setWhere
                                />
                            </Stack>

                            </>
                   
                        )}

                    <Grid spacing={4}>

                    <Grid item xs={12} sm={6} md={2} lg={0.8}>
                        {selectedRowId ?
                            <Button
                                sx={{ color: 'white' }}
                                size="medium"
                                variant="contained"
                                color="primary"
                                onClick={handleUpdateData}
                            >
                                Update
                            </Button> :
                            <Button
                                sx={{ color: 'white' }}
                                size="medium"
                                variant="contained"
                                color="primary"
                                onClick={handleAddData}
                            >
                                Add
                            </Button>}
                    </Grid>
                    </Grid>

                    <Grid container spacing={2} sx={{ marginTop:"20px" }}>
                    <Grid item xs={12}>
                        <div style={{ height: 250, width: '100%' }}>
                            <DataGrid rows={dataRows} density={'compact'} columns={TABLE_HEAD} getRowId={(row) => dataRows.indexOf(row)} components={{
                                Toolbar: GridToolbar,
                            }} />
                        </div>

                        </Grid>
                    </Grid>
                   
                    <Grid item xs={12}>
                        <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="center" spacing={2}>
                            <LoadingButton
                                size="medium"
                                type="submit"
                                variant="contained"
                                sx={{ color: 'white' }}
                                onClick={handleSave}
                            >
                                Save
                            </LoadingButton>
                            <Button
                                sx={{ color: 'white' }}
                                onClick={handelCancelClick}
                                size="medium"
                                variant="contained"
                                color="primary"
                            >
                                Cancel
                            </Button>
                        </Stack>
                    </Grid>
                </Stack>



            </Form>
            {openDeleteModal && (
                <ModalPopUp
                    openCloseValue={openDeleteModal}
                    msg={`Do you want to delete ?`}
                    confirm={confirmDelete}
                    cancel={handleCancelDelete}
                />
            )}
            {openModalForCancel && <ModalPopUp openCloseValue={setOpenModalCancel} msg={message.forCancel} confirm={forCancelConfirm} />}

        </FormikProvider>
    );
}