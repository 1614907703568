import { useState,useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
//
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import useResponsive from '../../hooks/useResponsive';

import AppCache from '../../services/AppCache';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 46;
const APP_BAR_DESKTOP = 46;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
  // background: '#F4F6F8',
    background: '#fff',
   // backgroundColor: '#26c6da'

});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 4,
    paddingBottom: theme.spacing(1),
    transition: 'margin-left 0.2s ease-in-out',
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 4,
   /* paddingLeft: theme.spacing(2), */
    paddingRight: theme.spacing(2)
  }
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
   

    const [open, setOpen] = useState(false);
    const isDesktop = useResponsive('up', 'md');
    const [width, setWidth] = useState(240);
    const { pathname } = useLocation(); 
    const CurrentLoggedInUser = AppCache.GetCurrentLoggedInUser();
    const navigate = useNavigate();
    
    const onCloseSidebar = () => {
        setOpen(true);
        setWidth(240);
    };

    const onOpenSidebar = () => {
        setOpen(false);
        setWidth(0);
       
    };
    const onCollapseSidebar = () => {
        setOpen(false); // Set to false when collapsing
        setWidth(0); // Set the width to 0 when collapsed
    };
    const handleChildItemClick = (MenuURL) => {
        if (MenuURL) {
            sessionStorage.setItem('menuUrl', MenuURL);
            const [url, id] = MenuURL.split('|');
            navigate(`/${url}?trackerId=${id}`);
            onCollapseSidebar(); // Collapse the sidebar on menu child click
        }
       
    };

    useEffect(() => {
        if (!isDesktop) {
            setOpen(false);
            setWidth(0);
        }
    }, [isDesktop]);

    useEffect(() => {
      if (open) {
            onCollapseSidebar(); // Collapse the sidebar for other pathname changes
        }
    }, [pathname]);

   

  return (
    <RootStyle>
      {/* <DashboardNavbar onOpenSidebar={() => setOpen(true)} /> */}
          {/* <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} /> */}
          
          <DashboardNavbar dwidth={width} openNav={open} onOpenSidebar={onCloseSidebar} />
          < DashboardSidebar isOpenSidebar={open} onCloseSidebar={onCloseSidebar} dwidth={width} onOpenSidebar={onOpenSidebar} handleChildItemClick={handleChildItemClick } />
          
          <MainStyle dwidth={open ? width : 0}>
              <Outlet />

          </MainStyle>
              
    </RootStyle>
  );
}
