import PropTypes from 'prop-types';
import { React, useState, useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Link,  Drawer, Typography, IconButton, Divider } from '@mui/material';
import Iconify from '../../components/Iconify';

// mock
import account from '../../_mock/account';
// hooks
import useResponsive from '../../hooks/useResponsive';
// components
import Scrollbar from '../../components/Scrollbar';
//
import AppCache from '../../services/AppCache';
import URLS from '../../services/urls.service';
import NavItemHeader from '../../components/NavItemHeader';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 250;
const DRAWER_HEGHT = 250;

const RootStyle = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('sm')]: {
        flexShrink: 0,
        width: DRAWER_WIDTH,
        height: DRAWER_HEGHT,
    },
}));

const AccountStyle = styled('div')(({ theme }) => ({
    display: 'flex',
    textDecoration: 'none',
    // padding: theme.spacing(2, 1),
    borderRadius: Number(theme.shape.borderRadius) * 1.5,
    justifyContent: 'center',
    color: 'white'
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#bf360c',
    minHeight: '50px',
    height:'50px',
   // ...theme.mixins.toolbar,
}));
// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
    isOpenSidebar: PropTypes.bool,
    onCloseSidebar: PropTypes.func,
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar, dwidth, onOpenSidebar , handleChildItemClick}) {
    const { pathname } = useLocation();

    const CurrentLoggedInUser = AppCache.GetCurrentLoggedInUser();

    
    const PreviousSmileUrl = AppCache.GetSmilePreviousURL();
   

    const isDesktop = useResponsive('up', 'md');

    const [menus, setDashboardMenus] = useState([]);

   

    const [username, setDashboardusername] = useState([]);
    useEffect(() => {
        if (!isOpenSidebar) {
            onOpenSidebar();
        }


        // PreviousUrl calls
        URLS.configFileURL().then((response) => {
            if (response.status === 200) {
                const config = JSON.parse(JSON.stringify(response.data));
                // AppCache.SetCurrentAPIURL(config.SmileServiceApiBaseUrl);
                AppCache.SetSmilePreviousURL(config);
               
            }
            else {
                // Failure response
                alert("Api URL  Failed");
            }
        })






    }, [pathname]);

    useEffect(() => {
        setDashboardMenus(CurrentLoggedInUser.Menus);
        setDashboardusername(CurrentLoggedInUser.ExecutiveName);
    }, [pathname]);
   
    const renderContent = (
        <Scrollbar
            sx={{
                height: 1,
                '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' }

            }}
        >
           
            {!isOpenSidebar && <>
                <nav sx={{ background: '#fbe9e7', alignItems: 'center' }}>
                    {menus.map((item, index) => {
                        return <NavItemHeader key={`${item.MenuName}-${index}`} item={item} onOpenSidebar='false' menuUrl={item.MenuURL} handleChildItemClick={handleChildItemClick} />;
                    })}
                </nav>
                </>
            }
            {isOpenSidebar && <>
               
                <nav sx={{ background: '#fbe9e7', alignItems: 'center' }}>
                    {menus.map((item, index) => {
                        return <NavItemHeader key={`${item.MenuName}-${index}`} item={item} onOpenSidebar='true' menuUrl={item.MenuURL} handleChildItemClick={handleChildItemClick} />;
                 })}      
                </nav>   
            </>
            }
            <Box sx={{ flexGrow: 1 }} />

        </Scrollbar>
    );

    return (
        <RootStyle sx={{
            width: { sm: dwidth }
        }}>
            {!isDesktop && (
                <Drawer
                    open={isOpenSidebar}
                    onClose={onCloseSidebar}
                    PaperProps={{
                        sx: {
                            width: DRAWER_WIDTH, backgroundColor: '#fbe9e7',
                        },
                    }}
                >

                    <DrawerHeader>
                        {isOpenSidebar &&

                            <>

                            <a textDecoration="none" href={PreviousSmileUrl}>

                                <AccountStyle>
                                    <Typography variant="subtitle2" sx={{ color: 'white', textDecoration:'none', fontSize: '0.8rem' }}>
                                            Continue with previous site
                                        </Typography>

                                    </AccountStyle>
                                </a>
                              
                                <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'white' }}>
                                    <Iconify icon="ic:outline-double-arrow" rotate="180deg" />
                                </IconButton>
                            </>
                        }

                        {!isOpenSidebar && <IconButton onClick={onCloseSidebar} sx={{ color: 'white' }}>
                            <Iconify icon="ic:outline-double-arrow" />
                        </IconButton>}
                    </DrawerHeader>

                    <Divider sx={{ m: 1 }} />

                    {renderContent}
                </Drawer>
            )}

            {isDesktop && (
                <Drawer
                    open={isOpenSidebar}
                    onClose={onCloseSidebar}
                    variant="permanent"
                    PaperProps={{
                        sx: {
                            width: dwidth, borderRightStyle: 'dashed', backgroundColor: '#fbe9e7',
                        },
                    }}
                >

                    <DrawerHeader >
                        {isOpenSidebar &&
                            <>
                              
                               
                                {/* <a target="_blank" href="http://13.126.160.44:85/SmileServiceUpgrade"> */}
                                <a  href={PreviousSmileUrl}>

                                        <AccountStyle>
                                    <Typography variant="subtitle2" sx={{ color: 'white', textDecoration: 'none', fontSize: '12px'}}>
                                                Continue with previous site
                                            </Typography>
                                       
                                        </AccountStyle>
                                </a>
                               
                            <IconButton onClick={onOpenSidebar} sx={{ color: 'white'}}>
                                    <Iconify icon="ic:outline-double-arrow" rotate="180deg" />
                                </IconButton>
                            </>
                        }

                        {!isOpenSidebar && <IconButton onClick={onCloseSidebar} sx={{ color: 'white' }}>
                            <Iconify icon="ic:outline-double-arrow" />
                        </IconButton>}
                    </DrawerHeader>
                    <Divider sx={{ m: 1 }} />
                    {renderContent}
                </Drawer>
            )}
        </RootStyle>
    );
}
