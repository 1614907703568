import { Navigate, useRoutes, useParams, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Blog from './pages/Blog';
import User from './pages/User';
import Login from './pages/Login';
import NotFound from './pages/Page404';
import Register from './pages/Register';
import Products from './pages/Products';
import BlankPage from './pages/BlankPage';
import LoginImageUploadPage from './pages/SidebarMenus/OtherMenu/LoginImageUploadPage';
import DashboardApp from './pages/DashboardApp';
import ProjectInformationPage from './pages/SidebarMenus/OtherMenu/ProjectInformation/ProjectInformationPage';
import ProjectInformationDetailsPage from './pages/SidebarMenus/OtherMenu/ProjectInformation/ProjectInformationDetailsPage';
import ClientMasterPage from './pages/SidebarMenus/MasterMenu/ClientMasterPage'
import ClientMasterDetailsPage from './pages/SidebarMenus/MasterMenu/ClientMasterDetailsPage';
import ClientInstallationPage from './pages/SidebarMenus/MasterMenu/ClientInstallationPage';
import ClientInstallationDetailsPage from './pages/SidebarMenus/MasterMenu/ClientInstallationDetailsPage';
import ProductMasterDetailsPage from './pages/SidebarMenus/MasterMenu/ProductMasterDetailsPage';
import ProductMasterPage from './pages/SidebarMenus/MasterMenu/ProductMasterPage';

import ServiceWeightagePage from './pages/SidebarMenus/ServiceMenu/ServiceWeightagePage';
import ServicesPage from './pages/SidebarMenus/ServiceMenu/ServicesPage';

import ContactMasterDetailsPage from './pages/SidebarMenus/MasterMenu/ContactMasterDetailsPage';
import ProfileDetails from './layouts/dashboard/ProfilelDetails';
import ServiceTimeReportPage from './pages/SidebarMenus/ReportsMenu/ServiceTimeReportPage';
import MarkAsAbsentPage from './pages/SidebarMenus/OtherMenu/MarkAsAbsentPage';
import URLS from './services/urls.service';
import RequestPage from './pages/SidebarMenus/ApprovalMenu/RequestPage';
import RequestDetailsPage from './pages/SidebarMenus/ApprovalMenu/RequestDetailsPage';
import RequestMasterPage from './pages/SidebarMenus/ApprovalMenu/RequestMasterPage';
import RequestMasterDetailsPage from './pages/SidebarMenus/ApprovalMenu/RequestMasterDetailsPage';
import ApprovalPage from './pages/SidebarMenus/ApprovalMenu/ApprovalPage';
import OAMasterListingPage from './pages/SidebarMenus/OAMenu/OAMasterListingPage';
import OAMasterDetailsPage from './pages/SidebarMenus/OAMenu/OAMasterDetailsPage';
import POMasterListingPage from './pages/SidebarMenus/OAMenu/POMasterListingPage';
import POMasterDetailsPage from './pages/SidebarMenus/OAMenu/POMasterDetailsPage';
import ProductLinkingPage from './pages/SidebarMenus/OAMenu/ProductLinkingPage';
import ProductLinkingDetailsPage from './pages/SidebarMenus/OAMenu/ProductLinkingDetailsPage';
import IRQuestionnairesListingPage from './pages/SidebarMenus/OAMenu/IRQuestionnairesListingPage';
import IRQuestionnairesDetailsPage from './pages/SidebarMenus/OAMenu/IRQuestionnairesDetailsPage';
import FillInstallationReportPage from './pages/SidebarMenus/OAMenu/FillInstallationReportPage';
import FillInstallationReportDetailsPage from './pages/SidebarMenus/OAMenu/FillInstallationReportDetailsPage';
import GenerateInstallationReportPage from './pages/SidebarMenus/OAMenu/GenerateInstallationReportPage';
import GenerateInstallationReportDetailsPage from './pages/SidebarMenus/OAMenu/GenerateInstallationReportDetailsPage';
import ApiMasterPage from './pages/SidebarMenus/MasterMenu/ApiMasterPage';
import ApiMasterDetailPage from './pages/SidebarMenus/MasterMenu/ApiMasterDetailPage';
import IncidentTracker from './pages/SidebarMenus/OtherMenu/IncidentTracker';
import CopyAccessRights from './pages/SidebarMenus/OtherMenu/CopyAccessRights';
import ExecutiveExpensesPage from './pages/SidebarMenus/MasterMenu/ExecutiveExpensesPage';
import ExecutiveExpensesDetailsPage from './pages/SidebarMenus/MasterMenu/ExecutiveExpensesDetailsPage';
import AllocationExecutiveExpensesTabPanel from './components/SidebarMenuComponents/MasterComponent/AllocationExecutiveExpensesTabPanel';
import IssueDeliveryDate from './pages/SidebarMenus/OtherMenu/IssueDeliveryDate';
import IssueDeliveryDateDetailsPage from './pages/SidebarMenus/OtherMenu/IssueDeliveryDateDetailsPage';
import Report from './components/SidebarMenuComponents/ReportComponent/Report';
import ServiceRequest from './components/SidebarMenuComponents/ApprovalComponent/ServiceRequest';
import ServiceRequestStatusReport from './components/SidebarMenuComponents/ReportComponent/ServiceRequestStatusReport';
import IssueCloser from './pages/SidebarMenus/ApprovalMenu/IssueCloser';
import ServiceRequestBDTracker from './pages/SidebarMenus/ServiceRequestMenu/ServiceRequestBDTracker';
import ServiceRequestBDTrackerDetails from './components/SidebarMenuComponents/ServiceRequestComponent/ServiceRequestBDTrackerDeatils';
import ServiceRequestBDTrackerListing from './pages/SidebarMenus/ServiceRequestMenu/ServiceRequestBDTrackerListing';
import AppCache from './services/AppCache';
import InstallationReportListing from './pages/SidebarMenus/OAMenu/InstallationReportListing';
import InstallationApproval from './pages/SidebarMenus/OAMenu/InstallationApproval';
import IRBillLinking from './pages/SidebarMenus/OAMenu/IRBillLinking';



// ----------------------------------------------------------------------

export default function Router() {
    const [menuUrl, setMenuUrls] = useState([]); // State to store an array of MenuURLs
    const CurrentLoggedInUser = AppCache.GetCurrentLoggedInUser(); // Get the current logged-in user
    const location = useLocation();
    useEffect(() => {
        
        setMenuUrls(sessionStorage.getItem('menuUrl')); // Store the full menuUrl string
        
    }, []);
    console.log("routes>>>>>>>>>>", sessionStorage.getItem('menuUrl'))
    // const { menuAlias } = useParams();

    
    // const menuParentId10Users = menus.filter((user) => user.MenuParentID === 83);
  return useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        // { path: 'app', element: <DashboardApp /> },
        { path: 'app', element: <DashboardApp /> },
        // { path: 'user', element: <User /> },
          { path: 'home', element: <DashboardApp />  },
        { path: 'products', element: <Products /> },
        { path: 'blog', element: <Blog /> },
        // { path: 'loginimageupload', element: <ChangeLoginImage /> },
       { path: 'other/loginimageupload', element: <LoginImageUploadPage /> },
       //  { path: 'loginimageupload', element: <ChangeLoginImage /> },
          { path: 'other/projectInformation', element: <ProjectInformationPage /> },

          { path: 'projectinformationdetailspage', element: <ProjectInformationDetailsPage /> },

          { path: 'master/clientmaster', element: <ClientMasterPage /> },
          { path: 'master/apimaster', element: <ApiMasterPage /> },
          { path: 'apimasterdetailpage', element: <ApiMasterDetailPage /> },

          { path: 'other/executiveexpenses', element: <ExecutiveExpensesPage /> },
          { path: 'executiveexpensesdetailspage', element: <ExecutiveExpensesDetailsPage /> },
          { path: 'allocationexecutiveexpenses', element: <AllocationExecutiveExpensesTabPanel /> },

          { path: 'clientmasterdetailspage', element: <ClientMasterDetailsPage /> },

          { path: 'master/clientinstallationlocations', element: <ClientInstallationPage /> },

          { path: 'clientinstallationdetailspage', element: <ClientInstallationDetailsPage /> },

          { path: 'contactmasterdetailspage', element: <ContactMasterDetailsPage /> },

          { path: 'master/productmaster', element: <ProductMasterPage /> },

          { path: 'productmasterdetailspage', element: <ProductMasterDetailsPage /> },


          { path: `service/serviceweightage`, element: <ServiceWeightagePage /> },

          { path: 'service/quickserviceentry', element: <ServicesPage/> },

          { path: 'profiledetails', element: <ProfileDetails /> },

          { path: 'reports/servicetimecontributionreport', element: <ServiceTimeReportPage /> },

          { path: 'reports/executiveexpensereport', element: <Report /> },
          { path: 'reports/servicerequeststatusreport', element: <ServiceRequestStatusReport/> },



          // ...menuParentId10Users.map((user) => ({
          //    path: `/dashboard/other/${user.menuAlias}`,
          //    element: <MarkAsAbsentPage />,
          //    key: user.ID.toString(),
          // })),
         // menuParentId10Users.map((user) => ({ key: user.ID, path: 'other/:menuAlias', element: <MarkAsAbsentPage /> }))





          { path: 'other/markasabsent', element: <MarkAsAbsentPage /> },

          { path: 'other/incidenttracker', element: <IncidentTracker /> },
         
          { path: 'other/copyaccessrights', element: <CopyAccessRights /> },
          { path: 'requestandapproval/requestcategory', element: <RequestPage /> },

          { path: 'requestandapproval/servicerequest', element: <ServiceRequest /> },

          { path: 'requestandapproval/issuecloser', element: <IssueCloser /> },


          { path: 'requestdetailspage', element: <RequestDetailsPage /> },

          { path: 'requestandapproval/requestmaster', element: <RequestMasterPage /> },

          { path: 'requestmasterdetailspage', element: <RequestMasterDetailsPage /> },

          { path: 'requestandapproval/requestapproval', element: <ApprovalPage /> },

          { path: 'other/issuedeliverydate', element: <IssueDeliveryDate /> },
          { path: 'issuedeliverydatedetailspage', element: <IssueDeliveryDateDetailsPage /> },


          { path: 'oa/oamaster', element: <OAMasterListingPage /> },
          { path: 'oamasterdetailspage', element: <OAMasterDetailsPage /> },
          { path: 'oa/pomaster', element: <POMasterListingPage /> },
          { path: 'pomasterdetailspage', element: <POMasterDetailsPage /> },
          { path: 'oa/productlinking', element: <ProductLinkingPage /> },
          { path: 'productlinkingdetailscomponent', element: <ProductLinkingDetailsPage /> },
          { path: 'oa/installationreportquestionaries', element: <IRQuestionnairesListingPage /> },
          { path: 'irquestionnairesdetailscomponent', element: <IRQuestionnairesDetailsPage/> },
          { path: 'oa/fillintallationreport', element: <FillInstallationReportPage/> },
          { path: 'fillinstallationreportdetailspage', element: <FillInstallationReportDetailsPage /> },
          { path: 'oa/generateintallationreport', element: <GenerateInstallationReportPage /> },
          { path: 'generateinstallationreportdetailspage', element: <GenerateInstallationReportDetailsPage /> },
          { path: 'oa/installationreport', element: <InstallationReportListing/> },
          { path: 'oa/installationapproval', element: <InstallationApproval/> },
          { path: 'oa/installationreportbilllinking', element: <IRBillLinking/> },

          // service request
          { path: 'servicerequest/servicerequesttracker', element: <ServiceRequestBDTrackerListing menuUrl={sessionStorage.getItem('menuUrl')} /> },
          { path: 'servicerequesttrackerdetailspage', element: <ServiceRequestBDTracker/>},



          
      ],
    },
    {
      path: 'register',
      element: <Register />,
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
        children: [
        { path: '/', element: <Navigate to="/login" /> },
        { path: 'login', element: <Login /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);
}
