
import * as Yup from 'yup';
// material
import { Stack, Button, TextareaAutosize, Divider, Select, MenuItem, Modal, Box, IconButton } from '@mui/material';
import Moment from 'moment';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { React, useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';

const accordionStyle = {
    backgroundColor: '#fbe9e7',
    minHeight: '10px',
    height: '30px',
    marginTop: '10px',
    marginBottom: '10px'
    // Add other CSS properties as needed
};

const remarkTextFieldStyle = {
    width: '48.5%'
}
const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    maxHeight: '80vh',
    overflow: 'auto',
    borderRadius: '8px',
};

export default function IssueCloserTicket({ handleTicketRemarkChange, ticketModifiedRows, saveTicket, handelCancelClick, ticketData, handleActionChange, ticketActionDropdown,
    handleIssueIdClick, ticketServiceDatavisible, ticketServiceData, openModal, handleOpenModal, handleCloseModal
}) {
 
    const ticket = [
        {
            field: 'IssueId',
            headerName: 'ID',
            width: 80,
            renderCell: (params) => {
                const issueId = params.row.IssueId;

                return (
                    <div
                        role="button" // Adds accessibility role
                        tabIndex={0} // Makes the div focusable
                        style={{
                            cursor: 'pointer',
                            color: 'blue',
                        }}
                        onMouseEnter={(e) => {
                            e.currentTarget.style.textDecoration = 'underline';
                        }}
                        onMouseLeave={(e) => {
                            e.currentTarget.style.textDecoration = 'none';
                        }}
                        onClick={() => handleOpenModal(issueId)}
                        onKeyDown={(e) => {
                            // Adds keyboard interactivity
                            if (e.key === 'Enter' || e.key === ' ') {
                                handleOpenModal(issueId);
                            }
                        }}
                    >
                        {params.value}
                    </div>
                );
            },
        },
        { field: 'Issuedate', headerName: 'Date',  renderCell: (params) => Moment(params.value).format('DD-MMM-YYYY') },
        { field: 'Remark', headerName: 'Remarks', width: 300 },
        {
            field: 'Action',
            headerName: 'Action',
            flex: 1,
            renderCell: (params) => {
                const issueId = params.row.IssueId;
                const currentValue = ticketActionDropdown[issueId] !== undefined ? ticketActionDropdown[issueId] : "Select";

                return (
                    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                        {/* Dropdown with 30% width */}
                        <Select
                            value={currentValue}
                            onChange={(event) => handleActionChange(event, issueId)}
                            variant="outlined"
                            size="small"
                            style={{ flex: 0.3, marginRight: '10px' }} // 30% width
                        >
                            <MenuItem value="Select">Select</MenuItem>
                            <MenuItem value={1}>Reopened</MenuItem>
                            <MenuItem value={0}>Closed</MenuItem>
                        </Select>

                        {/* Textarea with 70% width, only shown when Reopened is selected */}
                        {currentValue === 1 && (
                            <TextareaAutosize
                                style={{
                                    flex: 0.7, // 70% width
                                    marginLeft: '10px',
                                    minWidth: 120,
                                    padding: '6px',
                                    fontFamily:'sans-serif',
                                }}
                                placeholder="Enter remark"
                                onKeyDown={(event) => {
                                    // Prevent default action if necessary
                                    if (event.key === ' ') {
                                        event.stopPropagation(); // Prevent the event from bubbling up
                                    }
                                }}
                                value={ticketModifiedRows[issueId]?.IssuesReopenRemark || ''}
                                onChange={(event) => handleTicketRemarkChange(event, issueId)}
                            />
                        )}
                    </div>
                );
            },
        }

    ];

    const ticketService = [
        { field: 'Date', headerName: 'Date', renderCell: (params) => Moment(params.value).format('DD-MMM-YYYY') },
        { field: 'TotalServiceMinutes', headerName: 'Duration' ,width:80},
        { field: 'ContactPerson', headerName: 'Executive' },
        { field: 'ServiceProvided', headerName: 'Service Remarks', width: 400 },
        
    ];

    return (
        <Stack>
            <>
                <div style={{ height: '65vh', width: '100%' }}>
                        <DataGrid
                            rows={ticketData}
                            columns={ticket}
                            density="compact"
                            getRowId={(row) => row.id || ticketData.indexOf(row)}
                            components={{ Toolbar: GridToolbar }}
                        getRowHeight={() => 'auto'}
                        pageSize={25} 
                           
                        />
                </div>
                <Modal open={openModal} onClose={handleCloseModal}>
                    <Box sx={modalStyle}>
                        <IconButton
                            onClick={handleCloseModal}
                            style={{
                                position: 'absolute',
                                top: '10px',
                                right: '10px',
                                color: 'grey',
                            }}
                        >
                            <CloseIcon />
                        </IconButton>

                        <div style={{ height: '60vh', width: '100%' }}>
                            <DataGrid
                                rows={ticketServiceData}
                                columns={ticketService}
                                density="compact"
                                getRowId={(row) => row.id || ticketData.indexOf(row)}
                                components={{ Toolbar: GridToolbar }}
                                getRowHeight={() => 'auto'}
                                pageSize={25} 
                            />
                        </div>

                    </Box>
                </Modal>
                <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="center" spacing={2}>

                    <LoadingButton
                        size="medium"
                        type="submit"
                        variant="contained"
                        sx={{ color: 'white' }}
                        onClick={saveTicket }
                    >
                        Save
                    </LoadingButton>


                </Stack>
            </>
        </Stack>
       
    );
}